import React from "react";
import logo from "../../../images/Footer-logo.png";
import TextLogo from "../../../images/Logo-word.png";
import "../../../style/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faMobileRetro,
  faUsersLine,
  faHandHoldingDollar,
  faCampground,
  faPersonDigging,
  faHandshake,
  faGlobe,
  faCircleExclamation,
  faFolderTree,
  faNotesMedical,
  faHandsHoldingChild,
  faGraduationCap,
  faHandBackFist,
  faEarthAmerica,
} from "@fortawesome/free-solid-svg-icons";
import Fade from "react-reveal/Fade";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
// import { useState } from "react";
// import { Blogs } from "../../Api/Blogs";

const Footer = () => {
  // const [accessApi, setAccessApi] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    e.target.reset();
  };

  return (
    <div>
      <div className="top_footer_section">
        <div className="container">
          <div className="logo_and_form_wrapper">
            <div className="logo">
              <div className="logo_text">
                <img className="mb-1" src={logo} alt="" />
                <img src={TextLogo} alt="" />
              </div>
              <div>
                <p>Organization For </p>
                <p>Women And Children Development</p>
              </div>
            </div>
            <div className="subscribe">
              <p>Subscribe for our newsletter</p>
              <form onSubmit={sendEmail}>
                <div className="">
                  <input
                    type="email"
                    className="h-12 w-8/12 p-2 border-none shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1"
                    placeholder="Your email"
                    name="email"
                    required
                  />
                  <button type="submit" className="button h-12 w-4/12">
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>

          <Fade left duration={2000} cascade>
            <div className="content_wrapper">
              <div className="services">
                <h3>Our Services</h3>
                <div className="single_service_wrap">
                  <FontAwesomeIcon className="icons" icon={faNotesMedical} />
                  <a href="/health" target={"_blank"}>
                    Health
                  </a>
                </div>
                <div className="single_service_wrap middle_gap">
                  <FontAwesomeIcon
                    className="icons"
                    icon={faHandsHoldingChild}
                  />
                  <a href="/livelihood" target={"_blank"}>
                    Livelihood
                  </a>
                </div>
                <div className="single_service_wrap">
                  <FontAwesomeIcon className="icons" icon={faGraduationCap} />
                  <a href="/education" target={"_blank"}>
                    Education
                  </a>
                </div>
                <div className="single_service_wrap middle_gap">
                  <FontAwesomeIcon className="icons" icon={faHandBackFist} />
                  <a href="/rights" target={"_blank"}>
                    Rights
                  </a>
                </div>
                <div className="single_service_wrap">
                  <FontAwesomeIcon className="icons" icon={faEarthAmerica} />
                  <a href="/culture" target={"_blank"}>
                    Culture
                  </a>
                </div>
                <div className="single_service_wrap middle_gap">
                  <FontAwesomeIcon className="icons" icon={faUsersLine} />
                  <a href="/climate" target={"_blank"}>
                    Climate
                  </a>
                </div>
              </div>

              <div className="support_work">
                <h3>Support Our Work</h3>
                <div className="single_support_work_wrap">
                  <FontAwesomeIcon
                    className="icons"
                    icon={faHandHoldingDollar}
                  />
                  <a href="/donation">
                    <p>Donate now</p>
                  </a>
                </div>
                <div className="single_support_work_wrap middle_gap">
                  <FontAwesomeIcon className="icons" icon={faCampground} />
                  <p>Campaign with us</p>
                </div>
                <div className="single_support_work_wrap">
                  <FontAwesomeIcon className="icons" icon={faPersonDigging} />
                  <p>Work with us</p>
                </div>
                <div className="single_support_work_wrap middle_gap">
                  <FontAwesomeIcon className="icons" icon={faHandshake} />
                  <p>Volunteer with us</p>
                </div>
                <div className="single_support_work_wrap">
                  <FontAwesomeIcon className="icons" icon={faGlobe} />
                  <p>Join the global movement</p>
                </div>
              </div>

              <div className="resource">
                <h3>Our Resources</h3>
                <div className="works">
                  <FontAwesomeIcon className="icons" icon={faFolderTree} />
                  <a href="/resources/Infrastructures" target={"_blank"}>
                    Infrastructures
                  </a>
                </div>
                <div className="works middle_gap">
                  <FontAwesomeIcon
                    className="icons"
                    icon={faCircleExclamation}
                  />
                  <a href="/resources/recruitment" target={"_blank"}>
                    Recruitment
                  </a>
                </div>
              </div>

              <div className="address">
                <h3>Home Address</h3>
                <div className="home_address">
                  <FontAwesomeIcon className="icons" icon={faLocationDot} />
                  <p>Shoiloshova, Balaghata, Bandarban, Bangladesh</p>
                </div>
                <div className="email">
                  <FontAwesomeIcon className="icons" icon={faEnvelope} />
                  <p>bnks.ed@gmail.com</p>
                </div>
                <div className="phone">
                  <FontAwesomeIcon className="icons" icon={faMobileRetro} />
                  <div>
                    <p>+88 01556-742358</p>
                    <p className="my-2">+88 02333302906</p>
                    <p>+88 02333302979</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="bottom_footer_section ">
        <div className="container">
          <div className="date_wrapper">
            <div className="privacy">
              <p>Privacy Policy Terms of Use</p>
            </div>

            <div className="right_part">
              <span className="icon_c">
                <FontAwesomeIcon className="caretDown" icon={faCopyright} />
              </span>
              <span className="year">{new Date().getFullYear()}</span>
              <span className="rights_reserved">
                Bolipara Nari Kalyan Somity
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
