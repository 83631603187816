import React from "react";
import { useEffect } from "react";
import "../../../style/about/securityPolicy.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import ImageOne from "../../../images/security-policy/1.jpg";
import ImageTwo from "../../../images/security-policy/2.jpg";
import ImageThree from "../../../images/security-policy/3.jpg";
import ImageFour from "../../../images/security-policy/4.jpg";
import ImageFive from "../../../images/security-policy/5.jpg";
import ImageSix from "../../../images/security-policy/6.jpg";
import ImageSeven from "../../../images/security-policy/7.jpg";
import ImageEight from "../../../images/security-policy/8.jpg";
import ImageNine from "../../../images/security-policy/9.jpg";
import ImageTen from "../../../images/security-policy/10.jpg";
import ImageEleven from "../../../images/security-policy/11.jpg";
import ImageTwelve from "../../../images/security-policy/12.jpg";
import ImageThirtheen from "../../../images/security-policy/13.jpg";

const SecurityPolicy = () => {
  useEffect(() => {
    document.title = `Bnks | Security Policy`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/security-policy";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="security_policy_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="security_policy_wrapper">
          <div className="container">
            <h2>Security Policy</h2>
            <div className="content_wrapper">
              <img src={ImageOne} alt="" />
              <img src={ImageTwo} alt="" />
              <img src={ImageThree} alt="" />
              <img src={ImageFour} alt="" />
              <img src={ImageFive} alt="" />
              <img src={ImageSix} alt="" />
              <img src={ImageSeven} alt="" />
              <img src={ImageEight} alt="" />
              <img src={ImageNine} alt="" />
              <img src={ImageTen} alt="" />
              <img src={ImageEleven} alt="" />
              <img src={ImageTwelve} alt="" />
              <img src={ImageThirtheen} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SecurityPolicy;
