import React from "react";
import { useEffect } from "react";
import "../../../style/about/messageFromEd.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const MessageFromEd = () => {
  useEffect(() => {
    document.title = `Bnks | Message From Ed`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/message-from-ed";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="message_fromEd_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="message_fromEd_wrapper">
          <div className="container">
            <h2>Message from Executive Director</h2>
            <div className="content_wrapper">
              <p className="">This page is a work in progress</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MessageFromEd;
