import React, { useState } from "react";
import { useEffect } from "react";
import "../../../style/allActivity/allActivity.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import SocialMedia from "../../SocialMedia/SocialMedia";
import { Link } from "react-router-dom";

const AllActivity = () => {
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    const response = await fetch(`https://admin.bnksbd.org/posts`);
    setBlogs(await response.json());
  };

  useEffect(() => {
    getBlogs();
  }, []);

  useEffect(() => {
    document.title = `Bnks | All Activity`;
  }, []);

  const shareUrl = "https://bnksbd.org/all-activity";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="all_activity_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Budget Management Part */}
        <div className="all_activity_wrapper">
          <div className="container">
            <h2>All Activity Event</h2>
            <div className="activity_image_wrapper">
              {blogs.map((item) => {
                return (
                  <Link to={`/activity/${item.id}`} target={"_blank"}>
                    <div key={item.id} className="single_wrapper">
                      <div className="image_wrapper">
                        <p className="activity">Blog</p>
                        <div className="image_wrapper">
                          <img src={item.img_url} alt="" />
                        </div>
                      </div>
                      <div className="image_title">
                        <p>{item.date}</p>
                        <h5>{item.title}</h5>
                      </div>
                      <button>
                        <Link to={`/activity/${item.id}`} target={"_blank"}>
                          Read More
                          <FontAwesomeIcon
                            className="arrow_icon"
                            icon={faArrowRightLong}
                          />
                        </Link>
                      </button>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AllActivity;
