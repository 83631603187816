import React from "react";
import Health from "../../../images/health.png";
import Livelihood from "../../../images/livelihood.png";
import Education from "../../../images/Education.png";
import Rights from "../../../images/rihgt.jpg";
import Culture from "../../../images/culture.png";
import Climate from "../../../images/climates.jpg";
import "../../../style/goalAndObjective.css";

const GoalAndObjective = () => {
  return (
    <div className="goal_objective_section">
      <div className="goal_objective_wrapper">
        <div className="container">
          <h2>Goal And Objective</h2>
          <p>Creating meaningful change through our six synergetic programs</p>
          <div className="content_wrapper">
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/">
                  <img src={Health} alt="" />
                </a>
                <a href="/health" target={"_blank"}>
                  <div className="hover_background">
                    <h3>Health</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/">
                  <img src={Livelihood} alt="" />
                </a>
                <a href="/livelihood" target={"_blank"}>
                  <div className="hover_background">
                    <h3>Livelihood</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/">
                  <img src={Education} alt="" />
                </a>
                <a href="/education" target={"_blank"}>
                  <div className="hover_background">
                    <h3>Education</h3>
                  </div>
                </a>
              </div>
            </div>

            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/">
                  <img src={Rights} alt="" />
                </a>
                <a href="/rights" target={"_blank"}>
                  <div className="hover_background">
                    <h3>Rights</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/">
                  <img src={Culture} alt="" />
                </a>
                <a href="/culture" target={"_blank"}>
                  <div className="hover_background">
                    <h3>Culture</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/">
                  <img src={Climate} alt="" />
                </a>
                <a href="/climate" target={"_blank"}>
                  <div className="hover_background">
                    <h3>Climate</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalAndObjective;
