import React from "react";
import { useEffect } from "react";
import "../../../style/resource/annualReport.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const AnnualReport = () => {
  useEffect(() => {
    document.title = `Bnks | Annual Report`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/annual-report";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="annual_report_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Annual Report Part */}
        <div className="annual_report_wrapper">
          <h2>Annual Report</h2>
          <div className="content_wrapper">
            <div className="container">
              <div className="report_wrapper">
                <div className="report">
                  <p> Annual Report (2021 - 2022)</p>
                  <a
                    href="/BNKS_ANNUAL-REPORT-2022.pdf"
                    // download={"BNKS_ANNUAL-REPORT-2022.pdf"}
                    target={"_blank"}
                    id="style-2"
                    data-replace="Download Pdf"
                  >
                    <span> Download Pdf </span>
                  </a>
                </div>
                <div className="report mt-3">
                  <p> Annual Report (2020 - 2021)</p>
                  <a
                    href="/BNKS-Organizational-Report-January-December-2021.pdf"
                    // download={"BNKS-Organizational-Report-January-December-2021.pdf"}
                    target={"_blank"}
                    id="style-2"
                    data-replace="Download Pdf"
                  >
                    <span> Download Pdf</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AnnualReport;
