import React from "react";
import { useEffect } from "react";
import "../../../style/resource/factsheet.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import One from "../../../images/Fact-sheet/Bill Board Massage - A 1.jpg";
import Two from "../../../images/Fact-sheet/Bill Board Massage - B 1.jpg";
import Three from "../../../images/Fact-sheet/Child Massage.jpg";
import Four from "../../../images/Fact-sheet/Child Massage1.jpg";
import Five from "../../../images/Fact-sheet/Child Massage2.jpg";
import Six from "../../../images/Fact-sheet/Covid-19 Awareness.jpg";
import Seven from "../../../images/Fact-sheet/Covid-19.jpg";
import Eight from "../../../images/Fact-sheet/GBV_.jpg";
import Nine from "../../../images/Fact-sheet/Legal Aid..jpg";
import Ten from "../../../images/Fact-sheet/Legal Aid.jpg";
import Eleven from "../../../images/Fact-sheet/Stop GBV.jpg";
import Twelve from "../../../images/Fact-sheet/WhatsApp Image 2024-02-05 at 15.51.40_a03d2b8a.jpg";
import Thirteen from "../../../images/Fact-sheet/WhatsApp Image 2024-02-05 at 15.51.41_8f5aed41.jpg";
import Forteen from "../../../images/Fact-sheet/WhatsApp Image 2024-02-05 at 15.58.38_c2438eba.jpg";
import Fifteen from "../../../images/Fact-sheet/WhatsApp Image 2024-02-05 at 15.59.44_ef2dbc34.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const Factsheet = () => {
  useEffect(() => {
    document.title = `Bnks | Factsheet`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/factsheet";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="factsheet_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Factsheet Main Part */}
        <div className="factsheet_wrapper">
          <div className="container">
            <h2>Factsheet</h2>
            <div className="content_wrapper">
              <img src={One} alt="" />
              <img src={Two} alt="" />
              <img src={Three} alt="" />
              <img src={Four} alt="" />
              <img src={Five} alt="" />
              <img src={Six} alt="" />
              <img src={Seven} alt="" />
              <img src={Eight} alt="" />
              <img src={Nine} alt="" />
              <img src={Ten} alt="" />
              <img src={Eleven} alt="" />
              <img src={Twelve} alt="" />
              <img src={Thirteen} alt="" />
              <img src={Forteen} alt="" />
              <img src={Fifteen} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Factsheet;
