import React from "react";
import { useEffect } from "react";
import "../../../style/projects/projectCoverage.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const ProjectCoverage = () => {
  useEffect(() => {
    document.title = `Bnks | Project Coverage`;
  }, []);

  const shareUrl = "https://bnksbd.org/projects/project-coverage";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="project_coverage_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="project_coverage_wrapper">
          <h2>Project Coverage and Strategic Issues as per Thematic Areas</h2>
          <div className="content_wrapper">
            <div className="health">
              <div className="container">
                <h3>Health</h3>
                <p>
                  Experience in Health Sector covers 5 Upazilas (Thanchi,
                  Bandarban Sadar, Ruma, Lama and Alikadam) in Bandarban Hill
                  District, 4 Upazilas (Rangamati Sadar, Kaukhali, Kaptai and
                  Rajastholi) in Rangamati and 4 Upazilas (Manikchari, Ramgarh,
                  Matiranga, and Khagrachari Sadar) in Khagrachari District.
                </p>
                <p>
                  Focus areas of the sector are WASH, nutrition, primary health
                  care support, reproductive health rights, sanitation, rain
                  water harvesting, water supply (GFS), awareness, survivor
                  support, delivery support, traditional birth attendance
                  (capacity building training), legal support, water shed
                  management, herbal medicine (traditional scientific knowledge
                  and practice) and management & maintenance
                </p>
              </div>
            </div>

            <div className="container">
              <div className="livelihood">
                <h3>Livelihood</h3>
                <p>
                  BNKS has sound working experience in Livelihood sector covers
                  6 Upazilas out of 7 in Bandarban (Thanchi, Ruma, Bandarban
                  Sadar, Rowangchari, Lama and Alikadam).
                </p>
                <p>
                  It includes IGA support, entrepreneurship, market linkage and
                  value chain development, technical and vocational education
                  and training (TVET) and social safety net (VGD) on poultry
                  rearing, pig rearing, goat rearing, tailoring, ginger
                  production, banana, turmeric, coffee and cashew nut, homestead
                  gardening, handicraft, weaving, collection center and Turmeric
                  Processing Center.
                </p>
              </div>
            </div>

            <div className="education">
              <div className="container">
                <h3>EDUCATION</h3>
                <p>
                  Capability in education sector covers three (6) Upazilas like
                  Thanchi, Alikadam, Nykhongchari, Bandarban Sadar, Teknaf
                  Upazila, and Ramu Upazila.
                </p>
                <p>
                  Adult (Literacy) education, non-formal education, pre-primary
                  school, drop-out child, child space, Mother Language Education
                  (Mro language), child sponsorship, children forum and quality
                  education for primary school goers, etc. were the concentrated
                  areas.
                </p>
                <p>
                  Education support activities include learning materials,
                  sports and games materials, kid Bags & dresses and first aid
                  boxes, furniture’s, hygiene materials, etc.
                </p>
              </div>
            </div>

            <div className="container">
              <div className="rights">
                <h3>RIGHTS</h3>
                <p>
                  BNKS has good experience and developed skill in Rights sector.
                  Project intervention covered 6 Upazilas out of 7 in Bandarban
                  Hill District (Thanchi, Ruma, Bandarban Sadar, Rowangchari,
                  Lama, and Alikadam) and 2 Upazilas (Ramu and Ukhia) in Cox’s
                  Bazar supported by different national and international
                  donors.
                </p>
                <p>
                  Awareness and advocacy on women's rights and empowerment,
                  gender mainstreaming, Gender Base violence, child rights,
                  human rights, women networking with IGA intervention, Special
                  and disability.
                </p>
                <p>
                  Formation of the following groups on right-based issues added
                  an additional dimension in the sector - Paralegal Support
                  Committee (PSC), Union Social Support Committee (USSC),
                  Upazila Social Support Committee (UzSSC), Nari Jogajok Kendra
                  (NJK) and Linkage and member’s organization of District Legal
                  Aid Committee.
                </p>
              </div>
            </div>

            <div className="culture">
              <div className="container">
                <h3>CULTURE</h3>
                <p>
                  Development activities in cultural sector covered 3 Upazilas
                  out of 7 in Bandarban Hill District (Thanchi, Bandarban Sadar
                  and Alikadam).
                </p>
                <p>
                  Mentionable activities in the sector are- both traditional and
                  social sports and games, dance and drama, festivals,
                  Traditional Herbal Medicine Preservation and Fair, social
                  cohesion, Village Common Forest conservation and protection,
                  inter-religious exchange on sports and games materials,
                  sapling support, machinery support etc.
                </p>
                <p>
                  BNKS is one of the renowned local development organizations to
                  promote cultural pluralism, and cohesion through respect,
                  honor and dignity towards all the 11 ethnic communities in CHT
                  along with the Main communities of the country which was, and
                  will be the mandate and value of the organization.
                </p>
              </div>
            </div>

            <div className="container">
              <div className="environment">
                <h3>ENVIRONMENT & DRR</h3>
                <p>
                  Environment project implementation experience includes 4
                  Upazilas out of 7 in Bandarban Hill District (Thanchi, Lama,
                  Alikadam and Bandarban Sadar)
                </p>
                <p>
                  Major activities were awareness raising on climate change,
                  adaptation capability through school and college base session,
                  courtyard meeting/session at village level, form rights
                  holders’ groups, simulation and advocacy.
                </p>
                <p>
                  Capacity building to community peoples for managing Village
                  Common Forest, Food Security, Emergency Response (Land Slide
                  and Flash Flood), Early Recovery and Rehabilitation on Cash
                  support, Hygiene support, Homestead vegetables, and Poultry
                  etc.
                </p>
              </div>
            </div>

            <div className="governance">
              <div className="container">
                <h3>GOVERNANCE</h3>
                <p>
                  Governance sectors covers strengthening UP, Child Management
                  Committee (CMC), and Traditional Leaders, VDC, Religious
                  Leaders on their roles and responsibility and accountability.
                </p>
                <p>
                  Awareness linkage between beneficiaries and different
                  stakeholders.
                </p>
              </div>
            </div>

            <div className="container">
              <div className="networking">
                <h3>NETWORKING</h3>
                <p>
                  BNKS has strong networking skill and experience at local,
                  national and internation level. Since two decades of
                  development intervention it has the following achievements to
                  work with as Member of CAMPE, IUCN, IUCN-IPOs, Nari Jogajok
                  Kendra (NJK), ALRD, Democratic Budget Movement (DBM), Jatiyo
                  Nari Nirzaton Protirodh Forum (JNNPF), National Child TASK
                  Forum (NCTF), Food Security Network (Khani) and Executive
                  Committee Member of Khani, NAHAB and Executive Committee
                  Member of START FUND.
                </p>
                <p>
                  At service and implementation level it supported and
                  facilitated to form Child Forum, Youth Forum, Adolescent
                  Group, Community Journalist Group, Producer group, Farmers
                  Association and Community Base Organizations. /platforms Each
                  Forums/groups/network are active in networking, raise voice
                  and positively react on respective thematic issue and remain
                  functional even after many years of formation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProjectCoverage;
