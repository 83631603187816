import React from "react";
import { useEffect } from "react";
import "../../../style/about/constitution.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import ImageOne from "../../../images/constitution/1.jpg";
import ImageTwo from "../../../images/constitution/2.jpg";
import ImageThree from "../../../images/constitution/3.jpg";
import ImageFour from "../../../images/constitution/4.jpg";
import ImageFive from "../../../images/constitution/5.jpg";
import ImageSix from "../../../images/constitution/6.jpg";
import ImageSeven from "../../../images/constitution/7.jpg";

const Constitution = () => {
  useEffect(() => {
    document.title = `Bnks | Constitution`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/constitution";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="constitution_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="constitution_wrapper">
          <div className="container">
            <h2>BNKS Constitution</h2>
            <div className="content_wrapper">
              <img src={ImageOne} alt="" />
              <img src={ImageTwo} alt="" />
              <img src={ImageThree} alt="" />
              <img src={ImageFour} alt="" />
              <img src={ImageFive} alt="" />
              <img src={ImageSix} alt="" />
              <img src={ImageSeven} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Constitution;
