import React from "react";
import { useEffect } from "react";
import "../../../style/resource/payrollManagement.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SocialMedia from "../../SocialMedia/SocialMedia";

const PayrollManagement = () => {
  const [isShowQuestionOne, setIsShowQuestionOne] = useState(false);
  const [isShowQuestionTwo, setIsShowQuestionTwo] = useState(false);
  const [isShowQuestionThree, setIsShowQuestionThree] = useState(false);
  const [isShowQuestionFour, setIsShowQuestionFour] = useState(false);
  const [isShowQuestionFive, setIsShowQuestionFive] = useState(false);
  const [isShowQuestionSix, setIsShowQuestionSix] = useState(false);
  const [isShowQuestionSeven, setIsShowQuestionSeven] = useState(false);
  const [isShowQuestionEight, setIsShowQuestionEight] = useState(false);
  useEffect(() => {
    document.title = `Bnks | Payroll Management`;
  }, []);

  const shareUrl = "https://bnksbd.org/human-resources/payroll-management";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="payroll_management_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Payroll Management Part */}
        <div className="payroll_management_wrapper">
          <div className="container">
            <h2>Payroll Management</h2>
            <div className="content_wrapper">
              <h3>FAQ For Payroll Management</h3>
              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionOne(!isShowQuestionOne)}
                >
                  <h4>
                    Does the organization have a salary scale in operation?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionOne && (
                  <div className="answer">
                    <p>Yes</p>
                    <p>Highest Salary-35000-80000/- </p>
                    <p>Lowest Salary-3000-7000/-</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionTwo(!isShowQuestionTwo)}
                >
                  <h4>
                    Does the organization pay bonus or compensation to its
                    employees?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionTwo && (
                  <div className="answer">
                    <p>Yes</p>
                    <p>Yearly bonus is given in pro-rata basis </p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionThree(!isShowQuestionThree)}
                >
                  <h4>
                    Does the organization pay employers and employees
                    contributions and taxes to the relevant bodies each month?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionThree && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFour(!isShowQuestionFour)}
                >
                  <h4>Are tax payment documents archived?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFour && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFive(!isShowQuestionFive)}
                >
                  <h4>Is the salary scale regularly reviewed?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFive && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionSix(!isShowQuestionSix)}
                >
                  <h4>Does the organization monitor the cost of living?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionSix && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionSeven(!isShowQuestionSeven)}
                >
                  <h4>Is there a per diem policy?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionSeven && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionEight(!isShowQuestionEight)}
                >
                  <h4>
                    Are the regulations for the payments of final settlements
                    known and applied?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionEight && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PayrollManagement;
