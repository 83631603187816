import React from "react";
import { useEffect } from "react";
import "../../../style/focusArea/focusArea.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import AreaImage from "../../../images/Coverage-Areas.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const FocusArea = () => {
  useEffect(() => {
    document.title = `Bnks | Focus Area`;
  }, []);

  const shareUrl = "https://bnksbd.org/focusArea/focus-area";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="focus_area_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="focus_area_wrapper">
          <div className="container">
            <h2>Coverage Areas</h2>
            <p>
              <span>BNKS Coverage Areas:</span> As per organization legal
              authorization, constitution and mandates BNKS is legally
              authorized to implement project anywhere in Bangladesh. At
              present, BNKS is working in 4 districts and 14 Upazilas such as
              are showing with map where the BNKS presence geographically.
            </p>
            <div className="content_wrapper">
              <div className="image">
                <img src={AreaImage} alt="" />
              </div>
              <div className="right_part">
                <h2>BNKS Geographical Present</h2>
                <div className="bandarban_district">
                  <h3>Bandarban Hill District</h3>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Bandarban Sadar
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Thanchi Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Ruma Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Rowangchari Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Lama Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Alikadam Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Nykhongchari Upazila
                  </div>
                </div>

                <div className="rangamati_district">
                  <h3>Rangamati Hill District</h3>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Rangamati Sadar
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Kaptai Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Rajasthali Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Kawkhali Upazila
                  </div>
                </div>

                <div className="khagrachari_district">
                  <h3>Khagrachari Hill District</h3>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Khagrachari Sadar
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Matiranga Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Ramgarh Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Manikchari Upazila
                  </div>
                </div>

                <div className="cox_bazar">
                  <h3>Cox’s Bazar</h3>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Ramu Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Ukhia Upazila
                  </div>
                  <div className="single_upazila">
                    <FontAwesomeIcon className="circle_icon" icon={faCircle} />
                    Teknaf Upazila
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FocusArea;
