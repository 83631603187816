import React, { useState } from "react";
import logo from "../../../images/BNKS-LOGO.png";
import "../../../style/navbar.css";
import "../../../style/about/missionAndVision.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faAngleDown,
  faAngleUp,
  faChevronDown,
  faAngleRight,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpenAbout, setIsOpenAbout] = useState(false);
  const [isOpenAffiliation, setIsOpenAffiliation] = useState(false);
  const [isOpenFocusArea, setIsOpenFocusArea] = useState(false);
  const [isOpenProject, setIsOpenProject] = useState(false);
  const [isOpenDonor, setIsOpenDonor] = useState(false);
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isOpenResource, setIsOpenResource] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [isOpenGovernance, setIsOpenGovernance] = useState(false);
  const [isOpenPolicies, setIsOpenPolicies] = useState(false);
  const [isOpenExpertTeam, setIsOpenExpertTeam] = useState(false);
  const [isOpenStayInform, setIsOpenStayInform] = useState(false);

  return (
    <div className="navbar_section">
      <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 99 }}>
        <div className="navbar_wrapper">
          {/* Top Bar */}
          <div className="top_bar">
            <div className="container">
              <div className="button_wrapper">
                <div>
                  <a href="/">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="bnks_name">
                  <h1>Bolipara Nari Kalyan Somity</h1>
                </div>
                {/* <div className="search_bar">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="search_icon"
                  />
                  <input
                    className="search_input"
                    value={searchItem}
                    onChange={(e) => setSearchItem(e.target.value)}
                    type="text"
                    name="search"
                    placeholder="Type to search..."
                  />
                  <button className="button">Search</button>
                </div> */}
                <div className="donation_box">
                  <Link to="/donation">Donation Now</Link>
                </div>
              </div>
            </div>
          </div>

          {/* Main Navbar */}
          <div className="navbar_wrapper">
            <div className="navbar_container">
              <div className="menu">
                <nav className="navbar">
                  <ul>
                    <li>
                      <a href="/">
                        <FontAwesomeIcon className="homeIcons" icon={faHome} />
                      </a>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link>
                          About
                          <FontAwesomeIcon
                            className="caretDown"
                            icon={faChevronDown}
                          />
                        </Link>
                      </div>
                      <ul className="dropdown">
                        <li>
                          <Link
                            to="/about/message-from-ed"
                            className="sub-menu-item"
                          >
                            Messages from ED
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/about/bnks-background"
                            className=" sub-menu-item"
                          >
                            BNKS Background
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/about/mission-vision"
                            className=" sub-menu-item"
                          >
                            Mission & Vision Core Values and Objectives
                          </Link>
                        </li>
                        <li>
                          <div className="sub_menu_and_caret_wrapper">
                            <Link className=" sub-menu-item">
                              Governance & Policies
                            </Link>
                            <FontAwesomeIcon
                              className="sub_dropdown_caret"
                              icon={faAngleRight}
                            />
                          </div>

                          <ul className="second_dropdown">
                            <li>
                              <Link to="/about/constitution">
                                BNKS Constitution
                              </Link>
                            </li>
                            <li>
                              <Link to="/about/audit-system">Audit System</Link>
                            </li>
                            <li>
                              <div className="double_sub_menu_and_caret_wrapper">
                                <Link>Policy Terms</Link>
                                <FontAwesomeIcon
                                  className="double_sub_dropdown_caret"
                                  icon={faAngleRight}
                                />
                              </div>
                              <ul className="third_dropdown">
                                <li>
                                  <Link
                                    to="/about/legal-affiliation"
                                    className="sub-menu-item"
                                  >
                                    Origin & Legal Affiliation
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/child-protection-policy"
                                    className="sub-menu-item"
                                  >
                                    Child Protection Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/code-of-conduct-policy"
                                    className="sub-menu-item"
                                  >
                                    Code of Conduct Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/conflict-of-interest-policy"
                                    className="sub-menu-item"
                                  >
                                    Conflict of Interest Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/emergency-response-policy"
                                    className="sub-menu-item"
                                  >
                                    Emergency Response Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/environment-policy"
                                    className="sub-menu-item"
                                  >
                                    Environment Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/financial-management-policy"
                                    className="sub-menu-item"
                                  >
                                    Financial Management Manual Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/general-audit-tool"
                                    className="sub-menu-item"
                                  >
                                    Gender Audit Tools
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/gender-policy"
                                    className="sub-menu-item"
                                  >
                                    Gender Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/hr-policy"
                                    className="sub-menu-item"
                                  >
                                    HR Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/money-laundering-terrorist-financial-policy"
                                    className="sub-menu-item"
                                  >
                                    Money Laundering & Terrorist Financial
                                    Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/protection-from-sexual-exploitation-and-abuse"
                                    className="sub-menu-item"
                                  >
                                    Protection from Sexual Exploitation and
                                    Abuse
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/safeguarding-behavioral-security-policy"
                                    className="sub-menu-item"
                                  >
                                    Safeguarding Behavioral & Security Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/security-policy"
                                    className="sub-menu-item"
                                  >
                                    Security Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/travel-policy"
                                    className="sub-menu-item"
                                  >
                                    Travel Policy
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/about/whistle-blowing-policy"
                                    className="sub-menu-item"
                                  >
                                    Whistle Blowing Policy
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link
                            to="/about/bnks-profile"
                            className=" sub-menu-item"
                          >
                            BNKS Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/about/organogram"
                            className=" sub-menu-item"
                          >
                            Organogram
                          </Link>
                        </li>
                        <li>
                          <div className="sub_menu_and_caret_wrapper">
                            <Link className=" sub-menu-item">Council</Link>
                            <FontAwesomeIcon
                              className="sub_dropdown_caret"
                              icon={faAngleRight}
                            />
                          </div>
                          <ul className="second_dropdown">
                            <li>
                              <Link
                                to="/about/general-council"
                                className=" sub-menu-item"
                              >
                                General Council
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/about/executive-council"
                                className=" sub-menu-item"
                              >
                                Executive Council
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/about/advisory-board"
                                className=" sub-menu-item"
                              >
                                Advisory Board
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/about/senior-management-team"
                                className=" sub-menu-item"
                              >
                                Senior Management Team (SMT)
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link
                            to="/about/consulting-agency"
                            className=" sub-menu-item"
                          >
                            BNKS Consulting Agency / Firms
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/about/intervention-principles"
                            className="sub-menu-item"
                          >
                            Intervention Principles and Values
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link>
                          Affiliation
                          <FontAwesomeIcon
                            className="caretDown"
                            icon={faChevronDown}
                          />
                        </Link>
                      </div>
                      <ul>
                        <li>
                          <Link to="/affiliation/ngoab">NGOAB</Link>
                        </li>
                        <li>
                          <Link to="/affiliation/women-affairs-certificate">
                            Women Affairs
                          </Link>
                        </li>
                        <li>
                          <Link to="/affiliation/income-tax-certificate">
                            Income Tax
                          </Link>
                        </li>
                        <li>
                          <Link to="/affiliation/pador">PADOR</Link>
                        </li>
                        <li>
                          <Link to="/affiliation/sam-and-duns">
                            SAM and DUNS
                          </Link>
                        </li>
                        <li>
                          <Link to="/affiliation/tin-and-bin-certificate">
                            TIN and BIN
                          </Link>
                        </li>
                        <li>
                          <Link to="/affiliation/bank-certificate">
                            Bank Certificate
                          </Link>
                        </li>
                        <li>
                          <Link to="/affiliation/network-affiliation">
                            Network
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link>
                          Coverage Area
                          <FontAwesomeIcon
                            className="caretDown"
                            icon={faChevronDown}
                          />
                        </Link>
                      </div>
                      <ul>
                        <li>
                          <Link to="/focusArea/focus-area">Coverage Area</Link>
                        </li>
                        <li>
                          <Link to="/focusArea/strategic-plan">
                            Our Strategic Plan
                          </Link>
                        </li>
                        <li>
                          <Link to="/focusArea/other-thematic-issue">
                            Other Thematic Issues
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link>
                          Projects
                          <FontAwesomeIcon
                            className="caretDown"
                            icon={faChevronDown}
                          />
                        </Link>
                      </div>
                      <ul>
                        <li>
                          <Link to="/projects/ongoing-project">
                            Ongoing Projects
                          </Link>
                        </li>
                        <li>
                          <Link to="/projects/complete-project">
                            Completed Projects
                          </Link>
                        </li>
                        <li>
                          <Link to="/projects/project-coverage">
                            Project Coverage
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link>
                          Donors
                          <FontAwesomeIcon
                            className="caretDown"
                            icon={faChevronDown}
                          />
                        </Link>
                      </div>
                      <ul>
                        <li>
                          <Link to="/donor/current-donors">Current Donor</Link>
                        </li>
                        <li>
                          <Link to="/donor/past-donors">Past Donors</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link>
                          Resources
                          <FontAwesomeIcon
                            className="caretDown"
                            icon={faChevronDown}
                          />
                        </Link>
                      </div>
                      <ul>
                        <li>
                          <div className="sub_menu_and_caret_wrapper">
                            <Link>BNKS Expert Team</Link>
                            <FontAwesomeIcon
                              className="sub_dropdown_caret"
                              icon={faAngleRight}
                            />
                          </div>
                          <ul>
                            <li>
                              <Link to="/resources/admin-team">Admin Team</Link>
                            </li>
                            <li>
                              <Link to="/resources/finance-team">
                                Finance Team
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/internal-control-and-audit-compliance-unit">
                                Internal Control & Audit Compliance Unit
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/internal-monitoring-unit">
                                Internal Monitoring Unit
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/resources/bnks-vendor-list">
                            BNKS Vendor List
                          </Link>
                        </li>
                        <li>
                          <Link to="/resources/award-of-bnks">
                            Award of BNKS
                          </Link>
                        </li>
                        <li>
                          <Link to="/resources/bnks-professional-volunter">
                            BNKS Professional Volunter
                          </Link>
                        </li>
                        <li>
                          <div className="sub_menu_and_caret_wrapper">
                            <Link>Financial Management</Link>
                            <FontAwesomeIcon
                              className="sub_dropdown_caret"
                              icon={faAngleRight}
                            />
                          </div>
                          <ul className="resource_dropdown">
                            <li>
                              <Link to="/resources/budget-management">
                                Budget Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/cash-management">
                                Cash Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/contract-management">
                                Contract Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/infrastructures">
                                Infrastructures
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/it-communication">
                                It Communication
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/procurement">
                                Procurement
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/recruitment">
                                Recruitment
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/administrative-management">
                                Administrative Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/payroll-management">
                                Payroll Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/training-management">
                                Training Management
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/resources/annual-report">
                            Annual Report
                          </Link>
                        </li>
                        <li>
                          <Link to="/resources/audit-report">Audit Report</Link>
                        </li>
                        <li>
                          <Link to="/resources/photo-gallery">
                            Photo Gallery
                          </Link>
                        </li>
                        <li>
                          <Link to="/resources/factsheet">Factsheet</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link>
                          Notice Board
                          <FontAwesomeIcon
                            className="caretDown"
                            icon={faChevronDown}
                          />
                        </Link>
                      </div>
                      <ul>
                        <li>
                          <Link to="/notice-board/upcoming-events">
                            Upcoming Events
                          </Link>
                        </li>
                        <li>
                          <Link to="/notice-board/office-circular">
                            Office Circular
                          </Link>
                        </li>
                        <li>
                          <Link to="/notice-board/tender-notice">
                            Tenders Notice
                          </Link>
                        </li>
                        <li>
                          <Link to="/notice-board/quotation-notice">
                            Quotation Notice
                          </Link>
                        </li>
                        <li>
                          <Link to="/notice-board/job-circular">
                            Job Circular
                          </Link>
                        </li>
                        <li>
                          <Link to="/notice-board/written-test-result">
                            Written Test Result
                          </Link>
                        </li>
                        <li>
                          <Link to="/notice-board/short-listed-candidates">
                            Short Listed Candidates
                          </Link>
                        </li>
                        <li>
                          <Link to="/notice-board/interview-and-final-result-sheet">
                            Interview & Final Result Sheet
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="menu_and_caret_wrapper">
                        <Link to="/contact">Contact</Link>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Toggle Menu Icon */}
      <div style={{ position: "fixed", top: 0, width: "100%", zIndex: 99 }}>
        <div className="mobile_icon icons">
          <div className="container">
            <button
              className="mobile_icon_bar"
              onClick={() => setIsShow(!isShow)}
            >
              {!isShow && <FontAwesomeIcon className="icon" icon={faBars} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navbar Part */}
      {isShow && (
        <div className="mobile_nav_section">
          <div className="container">
            <nav>
              <button
                className="mobile_icon_close_bar"
                onClick={() => setIsShow(!isShow)}
              >
                {isShow && (
                  <FontAwesomeIcon className="close_icon" icon={faXmark} />
                )}
              </button>
              <ul>
                <li>
                  <div
                    onClick={() => setIsOpenAbout(!isOpenAbout)}
                    className="mobileMenu_and_caret_wrapper"
                  >
                    <Link>About</Link>
                    {isOpenAbout ? (
                      <FontAwesomeIcon className="caretDown" icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon
                        className="caretDown"
                        icon={faAngleDown}
                      />
                    )}
                  </div>

                  {isOpenAbout && (
                    <ul className="dropdown">
                      <li>
                        <Link
                          to="/about/message-from-ed"
                          className="sub-menu-item"
                        >
                          Messages from ED
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/bnks-background"
                          className="sub-menu-item"
                        >
                          BNKS Background
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/mission-vision"
                          className="sub-menu-item"
                        >
                          Mission & Vision Core Values and Objectives
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/bnks-profile"
                          className=" sub-menu-item"
                        >
                          BNKS Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/about/organogram" className=" sub-menu-item">
                          Organogram
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/about/general-council"
                          className=" sub-menu-item"
                        >
                          General Council
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/executive-council"
                          className=" sub-menu-item"
                        >
                          Executive Council
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/advisory-board"
                          className=" sub-menu-item"
                        >
                          Advisory Board
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/senior-management-team"
                          className=" sub-menu-item"
                        >
                          Senior Management Team (SMT)
                        </Link>
                      </li>
                      <li>
                        <div
                          onClick={() => setIsOpenGovernance(!isOpenGovernance)}
                          className="sub_mobileMenu_and_caret_wrapper"
                        >
                          <Link className="dropdown_font_color">
                            Governance & Policies
                          </Link>
                          {isOpenGovernance ? (
                            <FontAwesomeIcon
                              className="sub_caretDown"
                              icon={faAngleUp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="sub_caretDown"
                              icon={faAngleDown}
                            />
                          )}
                        </div>
                        {isOpenGovernance && (
                          <ul className="second_dropdown">
                            <li>
                              <Link to="/about/constitution">
                                BNKS Constitution
                              </Link>
                            </li>
                            <li>
                              <Link to="/about/audit-system">Audit System</Link>
                            </li>
                            <li>
                              <div
                                onClick={() =>
                                  setIsOpenPolicies(!isOpenPolicies)
                                }
                                className="double_sub_mobileMenu_and_caret_wrapper"
                              >
                                <Link className="dropdown_font_color">
                                  Policy Terms
                                </Link>
                                {isOpenPolicies ? (
                                  <FontAwesomeIcon
                                    className="double_sub_caretDown"
                                    icon={faAngleUp}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="double_sub_caretDown"
                                    icon={faAngleDown}
                                  />
                                )}
                              </div>
                              {isOpenPolicies && (
                                <ul className="third_dropdown">
                                  <li>
                                    <Link
                                      to="/about/legal-affiliation"
                                      className="sub-menu-item"
                                    >
                                      Origin & Legal Affiliation
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/child-protection-policy"
                                      className="sub-menu-item"
                                    >
                                      Child Protection Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/code-of-conduct-policy"
                                      className="sub-menu-item"
                                    >
                                      Code of Conduct Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/conflict-of-interest-policy"
                                      className="sub-menu-item"
                                    >
                                      Conflict of Interest Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/emergency-response-policy"
                                      className="sub-menu-item"
                                    >
                                      Emergency Response Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/environment-policy"
                                      className="sub-menu-item"
                                    >
                                      Environment Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/financial-management-policy"
                                      className="sub-menu-item"
                                    >
                                      Financial Management Manual Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/general-audit-tool"
                                      className="sub-menu-item"
                                    >
                                      Gender Audit Tools
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/gender-policy"
                                      className="sub-menu-item"
                                    >
                                      Gender Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/hr-policy"
                                      className="sub-menu-item"
                                    >
                                      HR Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/money-laundering-terrorist-financial-policy"
                                      className="sub-menu-item"
                                    >
                                      Money Laundering & Terrorist Financial
                                      Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/protection-from-sexual-exploitation-and-abuse"
                                      className="sub-menu-item"
                                    >
                                      Protection from Sexual Exploitation and
                                      Abuse
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/safeguarding-behavioral-security-policy"
                                      className="sub-menu-item"
                                    >
                                      Safeguarding Behavioral & Security Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/security-policy"
                                      className="sub-menu-item"
                                    >
                                      Security Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/travel-policy"
                                      className="sub-menu-item"
                                    >
                                      Travel Policy
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/about/whistle-blowing-policy"
                                      className="sub-menu-item"
                                    >
                                      Whistle Blowing Policy
                                    </Link>
                                  </li>
                                </ul>
                              )}
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link
                          to="/about/consulting-agency"
                          className=" sub-menu-item"
                        >
                          BNKS Consulting Agency / Firms
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/about/intervention-principles"
                          className="sub-menu-item"
                        >
                          Intervention Principles and Values
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li onClick={() => setIsOpenAffiliation(!isOpenAffiliation)}>
                  <div className="mobileMenu_and_caret_wrapper">
                    <Link> Affiliation</Link>
                    {isOpenAffiliation ? (
                      <FontAwesomeIcon className="caretDown" icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon
                        className="caretDown"
                        icon={faAngleDown}
                      />
                    )}
                  </div>
                  {isOpenAffiliation && (
                    <ul className="dropdown">
                      <li>
                        <Link to="/affiliation/ngoab">NGOAB</Link>
                      </li>
                      <li>
                        <Link to="/affiliation/women-affairs-certificate">
                          Women Affairs
                        </Link>
                      </li>
                      <li>
                        <Link to="/affiliation/income-tax-certificate">
                          Income Tax
                        </Link>
                      </li>
                      <li>
                        <Link to="/affiliation/pador">PADOR</Link>
                      </li>
                      <li>
                        <Link to="/affiliation/sam-and-duns">SAM and DUNS</Link>
                      </li>
                      <li>
                        <Link to="/affiliation/tin-and-bin-certificate">
                          TIN and BIN
                        </Link>
                      </li>
                      <li>
                        <Link to="/affiliation/bank-certificate">
                          Bank Certificate
                        </Link>
                      </li>
                      <li>
                        <Link to="/affiliation/network-affiliation">
                          Network
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li onClick={() => setIsOpenFocusArea(!isOpenFocusArea)}>
                  <div className="mobileMenu_and_caret_wrapper">
                    <Link to="">Focus Area</Link>
                    {isOpenFocusArea ? (
                      <FontAwesomeIcon className="caretDown" icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon
                        className="caretDown"
                        icon={faAngleDown}
                      />
                    )}
                  </div>
                  {isOpenFocusArea && (
                    <ul>
                      <li>
                        <Link to="/focusArea/focus-area">Focus Area</Link>
                      </li>
                      <li>
                        <Link to="/focusArea/strategic-plan">
                          Our Strategic Plan
                        </Link>
                      </li>
                      <li>
                        <Link to="/focusArea/other-thematic-issue">
                          Other Thematic Issues
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <div
                    onClick={() => setIsOpenProject(!isOpenProject)}
                    className="mobileMenu_and_caret_wrapper"
                  >
                    <Link>Projects</Link>
                    {isOpenProject ? (
                      <FontAwesomeIcon className="caretDown" icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon
                        className="caretDown"
                        icon={faAngleDown}
                      />
                    )}
                  </div>
                  {isOpenProject && (
                    <ul>
                      <li>
                        <Link to="/projects/ongoing-project">
                          Ongoing Projects
                        </Link>
                      </li>
                      <li>
                        <Link to="/projects/complete-project">
                          Completed Projects
                        </Link>
                      </li>
                      <li>
                        <Link to="/projects/project-coverage">
                          Project Coverage
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <div
                    onClick={() => setIsOpenDonor(!isOpenDonor)}
                    className="mobileMenu_and_caret_wrapper"
                  >
                    <Link>Donors</Link>
                    {isOpenDonor ? (
                      <FontAwesomeIcon className="caretDown" icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon
                        className="caretDown"
                        icon={faAngleDown}
                      />
                    )}
                  </div>
                  {isOpenDonor && (
                    <ul>
                      <li>
                        <Link to="/donor/current-donors">Current Donor</Link>
                      </li>
                      <li>
                        <Link to="/donor/past-donors">Past Donors</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <div
                    onClick={() => setIsOpenResource(!isOpenResource)}
                    className="mobileMenu_and_caret_wrapper"
                  >
                    <Link>Resources</Link>
                    {isOpenResource ? (
                      <FontAwesomeIcon className="caretDown" icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon
                        className="caretDown"
                        icon={faAngleDown}
                      />
                    )}
                  </div>
                  {isOpenResource && (
                    <ul>
                      <li>
                        <div
                          onClick={() => setIsOpenExpertTeam(!isOpenExpertTeam)}
                          className="sub_mobileMenu_and_caret_wrapper"
                        >
                          <Link className="dropdown_font_color">
                            BNKS Expert Team
                          </Link>
                          {isOpenExpertTeam ? (
                            <FontAwesomeIcon
                              className="sub_caretDown"
                              icon={faAngleUp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="sub_caretDown"
                              icon={faAngleDown}
                            />
                          )}
                        </div>
                        {isOpenExpertTeam && (
                          <ul>
                            <li>
                              <Link to="/resources/admin-team">Admin Team</Link>
                            </li>
                            <li>
                              <Link to="/resources/finance-team">
                                Finance Team
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/internal-control-and-audit-compliance-unit">
                                Internal Control & Audit Compliance Unit
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/internal-monitoring-unit">
                                Internal Monitoring Unit
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link to="/resources/bnks-vendor-list">
                          BNKS Vendor List
                        </Link>
                      </li>
                      <li>
                        <Link to="/resources/award-of-bnks">Award of BNKS</Link>
                      </li>
                      <li>
                        <Link to="/resources/bnks-professional-volunter">
                          BNKS Professional Volunter
                        </Link>
                      </li>
                      <li>
                        <div
                          onClick={() => setIsOpenStayInform(!isOpenStayInform)}
                          className="sub_mobileMenu_and_caret_wrapper"
                        >
                          <Link className="dropdown_font_color">
                            Financial Management
                          </Link>
                          {isOpenStayInform ? (
                            <FontAwesomeIcon
                              className="sub_caretDown"
                              icon={faAngleUp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="sub_caretDown"
                              icon={faAngleDown}
                            />
                          )}
                        </div>
                        {isOpenStayInform && (
                          <ul>
                            <li>
                              <Link to="/resources/budget-management">
                                Budget Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/cash-management">
                                cash Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/contract-management">
                                Contract Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/infrastructures">
                                Infrastructures
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/it-communication">
                                It Communication
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/procurement">
                                Procurement
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/administrative-management">
                                Administrative Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/payroll-management">
                                Payroll Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/recruitment">
                                Recruitment
                              </Link>
                            </li>
                            <li>
                              <Link to="/resources/training-management">
                                Training Management
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link to="/resources/photo-gallery">Photo Gallery</Link>
                      </li>
                      <li>
                        <Link to="/resources/annual-report">Annual Report</Link>
                      </li>
                      <li>
                        <Link to="/resources/audit-report">Audit Report</Link>
                      </li>
                      <li>
                        <Link to="/resource/factsheet">Factsheet</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <div
                    onClick={() => setIsOpenNotice(!isOpenNotice)}
                    className="mobileMenu_and_caret_wrapper"
                  >
                    <Link>Notice Board</Link>
                    {isOpenNotice ? (
                      <FontAwesomeIcon className="caretDown" icon={faAngleUp} />
                    ) : (
                      <FontAwesomeIcon
                        className="caretDown"
                        icon={faAngleDown}
                      />
                    )}
                  </div>
                  {isOpenNotice && (
                    <ul>
                      <li>
                        <Link to="/notice-board/upcoming-events">
                          Upcoming Events
                        </Link>
                      </li>
                      <li>
                        <Link to="/notice-board/office-circular">
                          Office Circular
                        </Link>
                      </li>
                      <li>
                        <Link to="/notice-board/tender-notice">
                          Tenders Notice
                        </Link>
                      </li>
                      <li>
                        <Link to="/notice-board/quotation-notice">
                          Quotation Notice
                        </Link>
                      </li>
                      <li>
                        <Link to="/notice-board/job-circular">
                          Job Circular
                        </Link>
                      </li>
                      <li>
                        <Link to="/notice-board/written-test-result">
                          Written Test Result
                        </Link>
                      </li>
                      <li>
                        <Link to="/notice-board/short-listed-candidates">
                          Short Listed Candidates
                        </Link>
                      </li>
                      <li>
                        <Link to="/notice-board/interview-and-final-result-sheet">
                          Interview & Final Result Sheet
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  className="last_menu"
                  onClick={() => setIsOpenContact(!isOpenContact)}
                >
                  <div className="mobileMenu_and_caret_wrapper">
                    <Link to="/contact">Contact </Link>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default Navbar;
