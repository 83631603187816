import React from "react";
import Marquee from "react-fast-marquee";
// Current Partner Logos
import ActionAID from "../../../images/Partnership/Current-partner/ActionAid.png";
import BRAC from "../../../images/Partnership/Current-partner/BRAC.png";
import BSRM from "../../../images/Partnership/Current-partner/BSRM.jpg";
import Diakonian from "../../../images/Partnership/Current-partner/Diakonia.png";
import iDE from "../../../images/Partnership/Current-partner/iDE.png";
import MJf from "../../../images/Partnership/Current-partner/MJF.png";
import PlanInternationalBangladesh from "../../../images/Partnership/Current-partner/Plan International Bangladesh.png";
import WorldVisionBangladesh from "../../../images/Partnership/Current-partner/World Vision Bangladesh.png";

// Past Partner Logos
import ActionAid from "../../../images/Partnership/Past-partner/ActionAid.png";
import ADB from "../../../images/Partnership/Past-partner/ADB.png";
import AIDA from "../../../images/Partnership/Past-partner/AIDA.jpg";
import BangladeshNGO from "../../../images/Partnership/Past-partner/Bangladesh NGO Foundation.png";
import Brac from "../../../images/Partnership/Past-partner/BRAC.png";
import CaritasBangladesh from "../../../images/Partnership/Past-partner/Caritas Bangladesh.jpg";
import CSR from "../../../images/Partnership/Past-partner/CSR.jpg";
import DCA from "../../../images/Partnership/Past-partner/DCA.png";
import Diakonia from "../../../images/Partnership/Past-partner/Diakonia.png";
import FAO from "../../../images/Partnership/Past-partner/FAO.png";
import FreedomFoundation from "../../../images/Partnership/Past-partner/Freedom Foundation.png";
import GoB from "../../../images/Partnership/Past-partner/GoB.png";
import HellenKeller from "../../../images/Partnership/Past-partner/Hellen Keller.png";
import Helvetas from "../../../images/Partnership/Past-partner/HELVETAS Swiss Interco-operation.png";
import IDE from "../../../images/Partnership/Past-partner/iDE.png";
import IUCN from "../../../images/Partnership/Past-partner/IUCN.png";
import KNCF from "../../../images/Partnership/Past-partner/KNCF.png";
import LandIsLife from "../../../images/Partnership/Past-partner/Land is Life.png";
import MJF from "../../../images/Partnership/Past-partner/MJF.png";
import NGOForum from "../../../images/Partnership/Past-partner/NGO Forum for Public Health.png";
import NiwanoPeaceFoundation from "../../../images/Partnership/Past-partner/Niwano Peace Foundation.jpg";
import Oxfam from "../../../images/Partnership/Past-partner/Oxfam Logo.jpg";
import PlanInternational from "../../../images/Partnership/Past-partner/Plan International Bangladesh.png";
import SaveTheChildren from "../../../images/Partnership/Past-partner/Save the Children.png";
import Tarango from "../../../images/Partnership/Past-partner/Tarango.png";
import UnWomen from "../../../images/Partnership/Past-partner/UN Women.png";
import UNDP from "../../../images/Partnership/Past-partner/UNDP.png";
import Unicef from "../../../images/Partnership/Past-partner/UNICEF.png";
import WorldVision from "../../../images/Partnership/Past-partner/World Vision Bangladesh.png";
import WorldFish from "../../../images/Partnership/Past-partner/WorldFish.png";
import Logo from "../../../images/BNKS-LOGO.png";
import "../../../style/fundingPartner.css";

const FundingPartner = () => {
  return (
    <div className="fundingPartner_section">
      <div className="funding_title">
        <img src={Logo} alt="" />
        <h2>Our Partnership</h2>
      </div>
      <div className="current_partner">
        <h4>Our Current Partner</h4>
        <Marquee right>
          <div className="image_wrapper">
            <img src={ActionAID} alt="" />
            <img src={BRAC} alt="" />
            <img src={BSRM} alt="" />
            <img src={Diakonian} alt="" />
            <img src={iDE} alt="" />
            <img src={MJf} alt="" />
            <img src={PlanInternationalBangladesh} alt="" />
            <img src={WorldVisionBangladesh} alt="" />
          </div>
        </Marquee>
      </div>
      <div className="past_partner">
        <h4>Our Past Parters</h4>
        <Marquee>
          <div className="image_wrapper">
            <img src={ActionAid} alt="" />
            <img src={ADB} alt="" />
            <img src={AIDA} alt="" />
            <img src={BangladeshNGO} alt="" />
            <img src={Brac} alt="" />
            <img src={CaritasBangladesh} alt="" />
            <img src={CSR} alt="" />
            <img src={DCA} alt="" />
            <img src={Diakonia} alt="" />
            <img src={Helvetas} alt="" />
            <img src={FAO} alt="" />
            <img src={FreedomFoundation} alt="" />
            <img src={GoB} alt="" />
            <img src={HellenKeller} alt="" />
            <img src={Helvetas} alt="" />
            <img src={IDE} alt="" />
            <img src={IUCN} alt="" />
            <img src={KNCF} alt="" />
            <img src={LandIsLife} alt="" />
            <img src={MJF} alt="" />
            <img src={NGOForum} alt="" />
            <img src={NiwanoPeaceFoundation} alt="" />
            <img src={Oxfam} alt="" />
            <img src={PlanInternational} alt="" />
            <img src={SaveTheChildren} alt="" />
            <img src={Tarango} alt="" />
            <img src={UnWomen} alt="" />
            <img src={UNDP} alt="" />
            <img src={Unicef} alt="" />
            <img src={WorldVision} alt="" />
            <img src={WorldFish} alt="" />
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default FundingPartner;
