import React from "react";
import { useEffect } from "react";
import "../../../style/resource/vendorList.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const VendorList = () => {
  useEffect(() => {
    document.title = `Bnks | BNKS Vendor List`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/bnks-vendor-list";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="vendor_list_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Vendor List Main Part */}
        <div className="vendor_list_wrapper">
          <div className="container">
            <h2>BNKS Vendor List</h2>
            <h5>BNKS enlisted vendors for the year 2023-2024</h5>
            <div className="content_wrapper">
              <div className="title">
                <h4>SL</h4>
                <h4>Name of Vendors</h4>
                <h4>Vendors Address</h4>
                <h4>Remarks</h4>
              </div>
              <div className="single">
                <p>1</p>
                <p>Hotel A Amirabad & Biriani House</p>
                <p>No.3 Gali, Bandarban Bazar, Bandarban Sadar, Bandarban</p>
                <p>Food Supply</p>
              </div>
              <div className="single">
                <p>2</p>
                <p>Kya Kya House </p>
                <p>
                  Behind of Chairman Market, Balaghata Bazar, Bandarban Sadar,
                  Bandarban.
                </p>
                <p>Food Supply</p>
              </div>
              <div className="single">
                <p>3</p>
                <p>M/S Farid Store</p>
                <p>Main Raod, Bandarban Bazar, Bandarban Sadar, Bandarban</p>
                <p>Stationery Supply</p>
              </div>
              <div className="single">
                <p>4</p>
                <p>M.S Shaha Jalal Store</p>
                <p>
                  Mahabur Rahman Raod, Bandarban Bazar, Bandarban Sadar,
                  Bandarban
                </p>
                <p>Snacks Supply</p>
              </div>
              <div className="single">
                <p>5</p>
                <p>Quality Printers</p>
                <p>Main Road, ln front of Bandarban Press Club, Bandarban</p>
                <p>Banner Supply</p>
              </div>
              <div className="single">
                <p>6</p>
                <p>Bonatuli Ad</p>
                <p>Ujani Para, Bandarban Sadar, Bandarban.</p>
                <p>Banner Supply</p>
              </div>
              <div className="single">
                <p>7</p>
                <p>Prue Intonational Pevis</p>
                <p>Madhyam Para, Bandarban Purshava, Bandarban</p>
                <p>Jeep/Car</p>
              </div>
              <div className="single">
                <p>8</p>
                <p>Yousuf Abdulah Rent-Car</p>
                <p>Mohsin marketrani hot</p>
                <p>Jeep/Car</p>
              </div>
              <div className="single">
                <p>9</p>
                <p>Ramu School Library</p>
                <p>Chowmuhani, Ramu, Cox’s Bazar</p>
                <p>Stationery Supply</p>
              </div>
              <div className="single">
                <p>10</p>
                <p>Sizzle </p>
                <p>Chowmuhani, Ramu, Cox’s Bazar</p>
                <p>Snacks</p>
              </div>
              <div className="single">
                <p>11</p>
                <p>Misti Ban</p>
                <p>Garjonia Bazar, Ramu, Cox’s Bazar</p>
                <p>Snacks</p>
              </div>
              <div className="single">
                <p>12</p>
                <p>Hotel Raj Mahal </p>
                <p>Cha Bagan, Joarianala, Ramu, Cox’s Bazar</p>
                <p>Food Supply</p>
              </div>
              <div className="single">
                <p>13</p>
                <p>Almodina Bhat Ghor</p>
                <p>Cha Bagan, Joarianala, Ramu, Cox’s Bazar</p>
                <p>Food Supply</p>
              </div>
              <div className="single">
                <p>14</p>
                <p>Al Mokka Hotel</p>
                <p>Cha Bagan, Joarianala, Ramu, Cox’s Bazar</p>
                <p>Food Supply</p>
              </div>
              <div className="single">
                <p>15</p>
                <p>সুনজুক হোটেল এন্ড রেষ্টুরেন্ট</p>
                <p>
                  বিএন্ডএস সেন্টার,বিজন সরনী, রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ
                  ০১৬৪৮৫৮২৫৮৭
                </p>
                <p>খাবার হোটেল</p>
              </div>
              <div className="single">
                <p>16</p>
                <p>রিপ রিপ হোটেল এন্ড রেষ্টুরেন্ট</p>
                <p>
                  ষ্টোডিয়াম মার্কেট, রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ
                  ০১৬৪৫৯১৮৮৭৯
                </p>
                <p>খাবার হোটেল</p>
              </div>
              <div className="single">
                <p>17</p>
                <p>পিবির ভাত ঘর</p>
                <p>
                  প্রধান সড়ক, রাজবাড়ী এলকা, রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ
                  ০১৭৯৫৬১৮৬০২
                </p>
                <p>খাবার হোটেল</p>
              </div>
              <div className="single">
                <p>18</p>
                <p>পিবির ভাত ঘর মনি কম্পিউটার এন্ড প্রিন্টার্স</p>
                <p>
                  নিউ কোর্ট বিল্ডিং বনরুপা, রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ
                  ০১৫৫৬৭০০৯৫২
                </p>
                <p>প্রিন্টিং এন্ড প্রেস</p>
              </div>
              <div className="single">
                <p>19</p>
                <p>মেসার্স অনিন্দ্য</p>
                <p>
                  নিউ কোর্ট বিল্ডিং বনরুপা, রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ
                  ০১৫৫৬৭০০৯৫২ গাউছিয়া মার্কেট, নিউ কোর্ট রোড রাঙ্গামাটি। মোবাইলঃ
                  ০১৮১৭৭৩৩৮১৪
                </p>
                <p>প্রিন্টিং এন্ড প্রেস</p>
              </div>
              <div className="single">
                <p>20</p>
                <p>চৌধুরী প্রিন্টার্স</p>
                <p>বনরুপা, রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ ০১৭১১২৭০৮৮০</p>
                <p>প্রিন্টিং এন্ড প্রেস</p>
              </div>
              <div className="single">
                <p>21</p>
                <p>সানি কম্পিউটার</p>
                <p>আদালত সড়ক, রাঙ্গামাটি পার্বত্য জেলা মোবাইলঃ ০১৮২৯৬৯৭৮৯৩</p>
                <p>ষ্টেশনারী</p>
              </div>
              <div className="single">
                <p>22</p>
                <p>আজমী ইত্যাদি ষ্টোর</p>
                <p>
                  নিউ কোর্ট রোড রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ ০১৮৩৭৮৪১১৪৮
                </p>
                <p>ষ্টেশনারী</p>
              </div>
              <div className="single">
                <p>23</p>
                <p>মেসার্স মদিনা ষ্টোর</p>
                <p>
                  তবলছড়ি বাজার, রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ ০১৮৩৭৮৪১১৪৮
                </p>
                <p>ষ্টেশনারী</p>
              </div>
              <div className="single">
                <p>24</p>
                <p>হোটেল জুম প্যালেস</p>
                <p>
                  টিএন্ডটি, রাঙ্গামাটি হাসপাতালের গেইট সংলগ্ন, রাঙ্গামাটি
                  পার্বত্য জেলা মোবাইলঃ ০১৬২৫১০০০০০
                </p>
                <p>আবাসিক হোটেল</p>
              </div>
              <div className="single">
                <p>25</p>
                <p>পর্যটন মোটেল</p>
                <p>রাঙ্গামাটি পার্বত্য জেলা। মোবাইলঃ ০১৬৭৩৭৬২৫৫১</p>
                <p>আবাসিক হোটেল</p>
              </div>
              <div className="single">
                <p>26</p>
                <p>মোটেল জর্জ</p>
                <p>কলেজ গেইট, রাঙ্গামাটি পার্বত্য জেলা। ফোনঃ০১৫৫৮৪৮০৭০১</p>
                <p>আবাসিক হোটেল</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default VendorList;
