import React from "react";
import { useEffect } from "react";
import "../../../style/goalObjectivePage/climate.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import Comment from "../../shared/Comment/Comment";

const Climate = () => {
  useEffect(() => {
    document.title = `Bnks | Climate`;
  }, []);

  const shareUrl = "https://bnksbd.org/climate";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="climate_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Climate Part */}
        <div className="climate_wrapper">
          <h3>Climate</h3>
          <div className="content_wrapper">
            <div className="container">
              <p>
                People and the environment are protected and living sustainably
                alongside another. Community people of Bandarban are facing many
                problems due to climate change and practices that are affecting
                the local environment. The seasons play an active role in the
                rural people’s life, especially farming however the seasons are
                becoming more and more disrupted with monsoon coming earlier and
                heavier each year. The area is subject to landslides due to
                heavy rainfall in the wet season, deforestation, forest fire and
                land reforming to create more land for development.
              </p>
            </div>
          </div>

          <Comment />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Climate;
