import React from "react";
import { useEffect } from "react";
import "../../../style/donor/pastDonor.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import ActionAid from "../../../images/Donor/Past-donor/ActionAid.png";
import ADB from "../../../images/Donor/Past-donor/ADB.png";
import AIDA from "../../../images/Donor/Past-donor/AIDA.jpg";
import BRAC from "../../../images/Donor/Past-donor/BRAC.png";
import CIDA from "../../../images/Donor/Past-donor/Canadian International Development Agency (CIDA).png";
import Danida from "../../../images/Donor/Past-donor/Danida.jpg";
import EmbassyOfSweden from "../../../images/Donor/Past-donor/Embassy of Sweden.png";
import EmbassyOfSwitzerland from "../../../images/Donor/Past-donor/Embassy of Switzerland.png";
import ESDO from "../../../images/Donor/Past-donor/ESDO.jpg";
import EuropeanCommission from "../../../images/Donor/Past-donor/European Commission.png";
import FreedomFoundation from "../../../images/Donor/Past-donor/Freedom Foundation.png";
import GoB from "../../../images/Donor/Past-donor/GoB.png";
import Japan from "../../../images/Donor/Past-donor/Japan.png";
import KNCF from "../../../images/Donor/Past-donor/KNCF.png";
import MORU from "../../../images/Donor/Past-donor/MORU.jpg";
import Oxfam from "../../../images/Donor/Past-donor/Oxfam Logo.jpg";
import SaveTheChildrenAustralia from "../../../images/Donor/Past-donor/Save The Children Australia.jpg";
import SaveTheChildren from "../../../images/Donor/Past-donor/Save the children.png";
import StartFund from "../../../images/Donor/Past-donor/Start Fund.jpg";
import UKaid from "../../../images/Donor/Past-donor/UKaid.png";
import UNWomen from "../../../images/Donor/Past-donor/UN Women.png";
import UNICEF from "../../../images/Donor/Past-donor/UNICEF.png";
import USAID from "../../../images/Donor/Past-donor/USAID.png";
import WorldVision from "../../../images/Donor/Past-donor/World Vision Bangladesh.png";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import { Zoom } from "react-reveal";

const PastDonor = () => {
  useEffect(() => {
    document.title = `Bnks | Past Donor`;
  }, []);

  const shareUrl = "https://bnksbd.org/donor/past-donors";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="past_donor_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="past_donor_wrapper">
          <div className="container">
            <h2>Past Donors</h2>
            <div className="content_wrapper">
              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={ActionAid} alt="" />
                  <p>Action Aid</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={ADB} alt="" />
                  <p>ADB</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={AIDA} alt="" />
                  <p>AIDA</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={BRAC} alt="" />
                  <p> BRAC</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={CIDA} alt="" />
                  <p>Canadian International Development Agency (CIDA)</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={Danida} alt="" />
                  <p>Danida</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={EmbassyOfSweden} alt="" />
                  <p>Embassy Of Sweden</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={EmbassyOfSwitzerland} alt="" />
                  <p>Embassy Of Switzerland</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={ESDO} alt="" />
                  <p>ESDO</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={EuropeanCommission} alt="" />
                  <p>European Commission</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={FreedomFoundation} alt="" />
                  <p>Freedom Foundation</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={GoB} alt="" />
                  <p>GoB</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={Japan} alt="" />
                  <p>Japan</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={KNCF} alt="" />
                  <p>KNCF Japan</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={MORU} alt="" />
                  <p>MORU</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={Oxfam} alt="" />
                  <p>Oxfam</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={SaveTheChildrenAustralia} alt="" />
                  <p>Save The Children Australia</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={SaveTheChildren} alt="" />
                  <p>Save The Children</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={StartFund} alt="" />
                  <p>Start Fund</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={UKaid} alt="" />
                  <p>UKaid</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={UNWomen} alt="" />
                  <p>UNWomen</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={UNICEF} alt="" />
                  <p>UNICEF</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={USAID} alt="" />
                  <p>USAID</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={WorldVision} alt="" />
                  <p>World Vision Bangladesh</p>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PastDonor;
