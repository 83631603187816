import React from "react";
import { useEffect } from "react";
import "../../../style/focusArea/thematicIssue.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const ThematicIssue = () => {
  useEffect(() => {
    document.title = `Bnks | Other Thematic Issue`;
  }, []);

  const shareUrl = "https://bnksbd.org/focusArea/other-thematic-issue";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="thematic_issue_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="thematic_issue_wrapper">
          <h3>
            Thematic Area Wise Coverage of Beneficiaries from The Year of
            2001-2022
          </h3>
          <div className="content_wrapper">
            <div className="container">
              <div className="thematic_wrapper">
                <div className="serial">
                  <h5>SL</h5>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                  <p>7</p>
                </div>
                <div className="thematic_area">
                  <h5>Thematic Areas</h5>
                  <p>Gender</p>
                  <p>Livelihood</p>
                  <p>Governance</p>
                  <p>Rights</p>
                  <p>Health</p>
                  <p>Climate Change</p>
                  <p>Culture</p>
                </div>
                <div className="direct">
                  <h5>Direct</h5>
                  <p>10275</p>
                  <p>14559</p>
                  <p>4000</p>
                  <p>1610</p>
                  <p>8415</p>
                  <p>21100</p>
                  <p>220</p>
                  <p className="font-semibold">60,179</p>
                </div>
                <div className="indirect">
                  <h5>Indirect</h5>
                  <p>18600</p>
                  <p>31756</p>
                  <p>5000</p>
                  <p>0</p>
                  <p>14450</p>
                  <p>10000</p>
                  <p>700</p>
                  <p className="font-semibold">80,506</p>
                </div>
                <div className="total">
                  <h5>Total</h5>
                  <p>28875</p>
                  <p>46315</p>
                  <p>9000</p>
                  <p>1610</p>
                  <p>22865</p>
                  <p>31100</p>
                  <p>920</p>
                  <p className="font-semibold">140,685</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ThematicIssue;
