import React from "react";
import { useEffect } from "react";
import "../../../style/about/bnksBackground.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const BnksBackground = () => {
  useEffect(() => {
    document.title = `BNKS | Background`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/bnks-background";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="bnks_background_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="bnks_background_wrapper">
          <div className="container">
            <h2>BNKS Background</h2>
            <div className="content_wrapper">
              <p className="">
                Bolipara Nari Kalyan Somity (BNKS) is a women and child
                development organization and organized to establish the rights
                of marginalized people of the country. In the year 1991, on
                March 3rd, the organization started auspiciously from the remote
                village of Naikyong Para, Bolipara Union No. 4, Thanchi Upazila,
                Bandarban Hill District. Behind the establishment of the
                organization of BNKS, many remarkable struggle stories may not
                be able to be written. BNKS was established with the leadership
                of May Lung Ching and a group of energetic like-minded women
                social workers to promote the socio-economic and cultural status
                of the marginalized and socially excluded peoples of the
                country, especially focusing on Chittagong Hill Tracts of
                Bangladesh. A few struggling women from their village shaped
                potential self-employment opportunities and creative ideas of
                forming associations for wage discrimination and rights. It was
                by no means easy for a small number of women to organize and
                form an association at that time. On her initiative, with the
                women of her village, she started traditional culture and her
                traditions by weaving and pig rearing. It was out of imagination
                that the first women's organization would be established in
                Thanchi, which is known as a backward area of the country.
              </p>
              <p>
                Even if we wanted to highlight the underdeveloped communication
                system, education, health, and quality of life issues of Thanchi
                at that time, it could not be completed due to there was no
                opportunity for education, health, and self-employment of women
                in those areas. Moreover, the medical system was more friable.
                In particular, pregnant and post-pregnant mothers and children
                have to die due to lack of treatment and the distance of
                communication. Inspiring women's organization initiatives, the
                local Bolipara Army Camp extended financial support for several
                sewing machines. Later on, small-scale programs of training on
                pig rearing and sewing were started with women from different
                villages. Later on, it was taking up the government registration
                process as Somity. It was not easy at all; the big thing was to
                stay in Bandarban city and complete all the procedures for
                government registration. It would never have been possible
                without the support of the family as well as money. In this way,
                BNKS gained registration from the District Women Affairs
                Directorate in 1995. BNKS achieved its first registration as a
                women's organization in Thanchi Upazila.
              </p>
              <p>
                During the Peace Accord of Chittagong Hill Tracts (CHTs) in
                1997, Hla Singh Nue completed her Master's Degree from Dhaka
                Eden Women's College and started looking for public and private
                jobs. One day her mother said to her, “You have many friends. If
                you can handle my organization without looking for a job and
                with the support of your friends, you can serve the poor and
                distressed people in the marginal areas of the country. Besides
                this, by creating employment opportunities through this
                organization, unemployment can provide employment opportunities
                to many youths.” Heeding his mother, Hla Shing Nue agreed. As a
                result, May Lung Ching handed over the management of the
                organization to her daughter Hla Singh Nu in 1997. After getting
                registration from the Bangladesh Bureau of NGO Affairs in 2001,
                it created more opportunities to reach marginalized people.
                Organization founder, May Lung Ching will be forever remembered
                for her diligent efforts in establishing the BNKS organization
                and for making backward women self-reliant.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BnksBackground;
