import React from "react";
import { useEffect } from "react";
import "../../../style/goalObjectivePage/culture.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import Comment from "../../shared/Comment/Comment";

const Culture = () => {
  useEffect(() => {
    document.title = `Bnks | Culture`;
  }, []);

  const shareUrl = "https://bnksbd.org/culture";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="culture_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Education Part */}
        <div className="culture_wrapper">
          <h3>Culture</h3>
          <div className="content_wrapper">
            <div className="container">
              <p>
                Local people of Bandarban are free to practice and reserve their
                own cultures, values and customs. There are 13 different ethnic
                groups that are co-habiting the area of Bandarban Hill District.
                However due to extreme poverty people are unable to practice
                their cultural traditions. Traditional cultures are being
                mainstreamed into each other, values and customs are being
                forgotten.
              </p>
            </div>
          </div>

          <Comment />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Culture;
