import React from "react";
import { useEffect } from "react";
import "../../../style/resource/financeTeam.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import AbamongMarma from "../../../images/Finance-team/Abamong-marma.jpg";
import MyaMyaShing from "../../../images/Finance-team/Mya-mya-marma.jpg";
import ChainSaMongMarma from "../../../images/Finance-team/Chain-sa-mong-marma.jpg";
import ShimaTanchangya from "../../../images/Finance-team/Shima-tanchangya.jpg";
import UmmeMarma from "../../../images/Finance-team/blank-image.png";
import SaronMawiBawm from "../../../images/Finance-team/Saron-mawi-bawm.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const FinanceTeam = () => {
  useEffect(() => {
    document.title = `Bnks | Finance Team`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/finance-team";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="finance_team_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Budget Management Part */}
        <div className="finance_team_wrapper">
          <div className="container">
            <h2>BNKS Finance Team Members</h2>
            <div>
              <p>
                Money is one of the most important matters that one organization
                needs to handle to achieve its goals and objectives of the
                mission. This should be done with due integration and
                responsiveness. To do this, a formal organization needs to
                establish its business ethics, standards, norms, policy
                procedures, or guidelines as a formal financial management
                framework. Thus, the BNKS establishes its financial management
                framework through the establishment of the “Financial Policy and
                Procedures Manual (FPPM)” in the organization.
              </p>
              <p>
                This “Financial Policy and Procedures Manual (FPPM)” will be
                applicable as an integral part of the standard operating
                procedures (SOP) for BNKS, staffs, consultants, projects,
                sub-offices and all others concerned. The framework demonstrates
                the organizational concerns for financial integrity; as such it
                is important evidence of our accountability to all stakeholders.
                The finance function across the organization is charged with
                establishing and communicating the policies and procedures
                contained in the framework of FPPM.
              </p>
              <p>
                BNKS ensures standard financial and procurement management with
                integrity and transparency of financial and procurement
                activities at all levels, BNKS ensures proper use of resources
                and proper financial and procurement management of the
                organization. The Governing Committee and Executive Director are
                responsible for ensuring the effective and efficient use of
                donor funds according to the constitution. BNKS also ensures the
                application of Generally Accepted Accounting Principles (GAAP).
                BNKS ensures that standard control systems are practiced in its
                own financial and procurement procedures. The procedures develop
                to provide adequate internal control of BNKS.
              </p>
            </div>
            <div className="content_wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={AbamongMarma} alt="" />
                </div>
                <h4>Mr. Abamong Marma</h4>
                <h5>Finance Director</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={MyaMyaShing} alt="" />
                </div>
                <h4>Ms. Mya Mya Shing</h4>
                <h5>Finance Manager</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ChainSaMongMarma} alt="" />
                </div>
                <h4>Mr. Chain Sa Mong Marma</h4>
                <h5>Finance & Admin Officer</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ShimaTanchangya} alt="" />
                </div>
                <h4>Ms. Shima Tanchangya</h4>
                <h5>Finance & Admin Officer</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={UmmeMarma} alt="" />
                </div>
                <h4>Ms. Umme Marma</h4>
                <h5>Finance & Admin Officer</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SaronMawiBawm} alt="" />
                </div>
                <h4>Ms. Saron Mawi Bawm</h4>
                <h5>Finance & Admin Officer</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FinanceTeam;
