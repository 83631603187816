import React from "react";
import { useEffect } from "react";
import "../../../style/resource/administrativeManagement.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faPlus,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SocialMedia from "../../SocialMedia/SocialMedia";

const AdministrativeManagement = () => {
  const [isShowQuestionOne, setIsShowQuestionOne] = useState(false);
  const [isShowQuestionTwo, setIsShowQuestionTwo] = useState(false);
  const [isShowQuestionThree, setIsShowQuestionThree] = useState(false);
  const [isShowQuestionFour, setIsShowQuestionFour] = useState(false);
  const [isShowQuestionFive, setIsShowQuestionFive] = useState(false);
  const [isShowQuestionSix, setIsShowQuestionSix] = useState(false);
  useEffect(() => {
    document.title = `Bnks | Administrative Management`;
  }, []);

  const shareUrl =
    "https://bnksbd.org/human-resources/administrative-management";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="administrative_management_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Administrative Management Part */}
        <div className="administrative_management_wrapper">
          <div className="container">
            <h2>Administrative and Staff Management</h2>
            <div className="content_wrapper">
              <h3>FAQ For Administrative and Staff Management</h3>
              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionOne(!isShowQuestionOne)}
                >
                  <h4>
                    Do all employees have a personal file containing all
                    necessary documentation?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionOne && (
                  <div className="answer">
                    <p>Yes</p>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p>All documents of recruitment process Bio-data </p>
                    </div>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p> All related academic and experience certificates </p>
                    </div>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p>Leave related documents</p>
                    </div>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p> Job Descriptions </p>
                    </div>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p> Joining Letter </p>
                    </div>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p> Offer Letter </p>
                    </div>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p> Annual Appraisal </p>
                    </div>
                    <div className="inside_check_wrapper">
                      <FontAwesomeIcon className="check_icon" icon={faCircle} />
                      <p> All official letter (personal) </p>
                    </div>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionTwo(!isShowQuestionTwo)}
                >
                  <h4>Are working hours posted in the office?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionTwo && (
                  <div className="answer">
                    <p>Yes</p>
                    <p>Office time: 9:00am to 5:00 pm </p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionThree(!isShowQuestionThree)}
                >
                  <h4>
                    Does the organisation have a system of follow-up presence,
                    leaves, absenc?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionThree && (
                  <div className="answer">
                    <p>Yes</p>
                    <p>Overall evidence follow up</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFour(!isShowQuestionFour)}
                >
                  <h4>
                    Are personnel files archived according to a defined
                    archiving plan?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFour && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFive(!isShowQuestionFive)}
                >
                  <h4>Does each employee have an ID card?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFive && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionSix(!isShowQuestionSix)}
                >
                  <h4>
                    Does the organisation have a medical policy for national
                    employees?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionSix && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Disciplinary Procedure */}
        <div className="disciplinary_wrapper">
          <div className="container">
            <h5>The steps in the disciplinary procedure</h5>
            <div className="content_wrapper">
              <div className="inside_check_wrapper">
                <FontAwesomeIcon
                  className="warning_icon"
                  icon={faTriangleExclamation}
                />
                <p>
                  If any staff violate the rules & norms the following is taken
                </p>
              </div>
              <div className="inside_check_wrapper">
                <FontAwesomeIcon className="check_icon" icon={faCircle} />
                <p>Verbal warning</p>
              </div>
              <div className="inside_check_wrapper">
                <FontAwesomeIcon className="check_icon" icon={faCircle} />
                <p>Show cause notice</p>
              </div>
              <div className="inside_check_wrapper">
                <FontAwesomeIcon className="check_icon" icon={faCircle} />
                <p>Justification</p>
              </div>
              <div className="inside_check_wrapper">
                <FontAwesomeIcon className="check_icon" icon={faCircle} />
                <p>
                  Decision taken by management committee according to rules of
                  punishment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdministrativeManagement;
