import React from "react";
import { useEffect } from "react";
import "../../../style/about/financialManagementPolicy.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import ImageOne from "../../../images/financial-management/1.jpg";
import ImageTwo from "../../../images/financial-management/2.jpg";
import ImageThree from "../../../images/financial-management/3.jpg";
import ImageFour from "../../../images/financial-management/4.jpg";
import ImageFive from "../../../images/financial-management/5.jpg";
import ImageSix from "../../../images/financial-management/6.jpg";
import ImageSeven from "../../../images/financial-management/7.jpg";
import ImageEight from "../../../images/financial-management/8.jpg";
import ImageNine from "../../../images/financial-management/9.jpg";
import ImageTen from "../../../images/financial-management/10.jpg";
import ImageEleven from "../../../images/financial-management/11.jpg";
import ImageTwelve from "../../../images/financial-management/12.jpg";
import ImageThirtheen from "../../../images/financial-management/13.jpg";
import ImageFortheen from "../../../images/financial-management/14.jpg";
import ImageFiftheen from "../../../images/financial-management/15.jpg";
import ImageSixtheen from "../../../images/financial-management/16.jpg";
import ImageSeventheen from "../../../images/financial-management/17.jpg";
import ImageEighteen from "../../../images/financial-management/18.jpg";
import ImageNinetheen from "../../../images/financial-management/19.jpg";
import ImageTwenty from "../../../images/financial-management/20.jpg";
import ImageTwentyOne from "../../../images/financial-management/21.jpg";
import ImageTwentyTwo from "../../../images/financial-management/22.jpg";
import ImageTwentyThree from "../../../images/financial-management/23.jpg";
import ImageTwentyFour from "../../../images/financial-management/24.jpg";
import ImageTwentyFive from "../../../images/financial-management/25.jpg";
import ImageTwentySix from "../../../images/financial-management/26.jpg";
import ImageTwentySeven from "../../../images/financial-management/27.jpg";
import ImageTwentyEight from "../../../images/financial-management/28.jpg";
import ImageTwentyNine from "../../../images/financial-management/29.jpg";
import ImageThirty from "../../../images/financial-management/30.jpg";
import ImageThirthOne from "../../../images/financial-management/31.jpg";
import ImageThirthTwo from "../../../images/financial-management/32.jpg";
import ImageThirthThree from "../../../images/financial-management/33.jpg";
import ImageThirthFour from "../../../images/financial-management/34.jpg";
import ImageThirthFive from "../../../images/financial-management/35.jpg";
import ImageThirthSix from "../../../images/financial-management/36.jpg";
import ImageThirthSeven from "../../../images/financial-management/37.jpg";
import ImageThirthEight from "../../../images/financial-management/38.jpg";
import ImageThirthNine from "../../../images/financial-management/39.jpg";
import ImageForty from "../../../images/financial-management/40.jpg";
import ImageFortyOne from "../../../images/financial-management/41.jpg";
import ImageFortyTwo from "../../../images/financial-management/42.jpg";
import ImageFortyThree from "../../../images/financial-management/43.jpg";
import ImageFortyFour from "../../../images/financial-management/44.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const FinancialManagementPolicy = () => {
  useEffect(() => {
    document.title = `Bnks | Financial management Policy`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/financial-management-policy";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="financial_management_policy_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="financial_management_policy_wrapper">
          <div className="container">
            <h2>Financial management Policy</h2>
            <div className="content_wrapper">
              <img src={ImageOne} alt="" />
              <img src={ImageTwo} alt="" />
              <img src={ImageThree} alt="" />
              <img src={ImageFour} alt="" />
              <img src={ImageFive} alt="" />
              <img src={ImageSix} alt="" />
              <img src={ImageSeven} alt="" />
              <img src={ImageEight} alt="" />
              <img src={ImageNine} alt="" />
              <img src={ImageTen} alt="" />
              <img src={ImageEleven} alt="" />
              <img src={ImageTwelve} alt="" />
              <img src={ImageThirtheen} alt="" />
              <img src={ImageFortheen} alt="" />
              <img src={ImageFiftheen} alt="" />
              <img src={ImageSixtheen} alt="" />
              <img src={ImageSeventheen} alt="" />
              <img src={ImageEighteen} alt="" />
              <img src={ImageNinetheen} alt="" />
              <img src={ImageTwenty} alt="" />
              <img src={ImageTwentyOne} alt="" />
              <img src={ImageTwentyTwo} alt="" />
              <img src={ImageTwentyThree} alt="" />
              <img src={ImageTwentyFour} alt="" />
              <img src={ImageTwentyFive} alt="" />
              <img src={ImageTwentySix} alt="" />
              <img src={ImageTwentySeven} alt="" />
              <img src={ImageTwentyEight} alt="" />
              <img src={ImageTwentyNine} alt="" />
              <img src={ImageThirty} alt="" />
              <img src={ImageThirthOne} alt="" />
              <img src={ImageThirthTwo} alt="" />
              <img src={ImageThirthThree} alt="" />
              <img src={ImageThirthFour} alt="" />
              <img src={ImageThirthFive} alt="" />
              <img src={ImageThirthSix} alt="" />
              <img src={ImageThirthSeven} alt="" />
              <img src={ImageThirthEight} alt="" />
              <img src={ImageThirthNine} alt="" />
              <img src={ImageForty} alt="" />
              <img src={ImageFortyOne} alt="" />
              <img src={ImageFortyTwo} alt="" />
              <img src={ImageFortyThree} alt="" />
              <img src={ImageFortyFour} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FinancialManagementPolicy;
