import React from "react";
import { useEffect } from "react";
import "../../../style/donor/currentDonor.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import Zoom from "react-reveal/Zoom";
import Australian from "../../../images/Donor/Current-donor/Australian.png";
import Brac from "../../../images/Donor/Current-donor/BRAC.png";
import BSRM from "../../../images/Donor/Current-donor/BSRM.jpg";
import CIDA from "../../../images/Donor/Current-donor/Canadian International Development Agency (CIDA).png";
import EmbassyOfSwitzerland from "../../../images/Donor/Current-donor/Embassy of Switzerland.png";
import EuropeanCoFunded from "../../../images/Donor/Current-donor/European Co-funded.png";
import EuropeanCommission from "../../../images/Donor/Current-donor/European Commission.png";
import SIDA from "../../../images/Donor/Current-donor/SIDA.png";
import Unicef from "../../../images/Donor/Current-donor/UNICEF.png";
import WorldVision from "../../../images/Donor/Current-donor/World Vision Bangladesh.png";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const CurrentDonor = () => {
  useEffect(() => {
    document.title = `Bnks | Current Donor`;
  }, []);

  const shareUrl = "https://bnksbd.org/donor/current-donors";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="current_donor_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="current_donor_wrapper">
          <div className="container">
            <h2>Current Donors</h2>
            <div className="content_wrapper">
              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={Australian} alt="" />
                  <p>Australian</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={Brac} alt="" />
                  <p> Brac</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={BSRM} alt="" />
                  <p> Bangladesh Steel ReRolling Mills Ltd. (BSRM)</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={CIDA} alt="" />
                  <p></p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={EmbassyOfSwitzerland} alt="" />
                  <p>Embassy Of Switzerland</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={EuropeanCoFunded} alt="" />
                  <p>European Co Funded</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={EuropeanCommission} alt="" />
                  <p>European Commission</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={SIDA} alt="" />
                  <p>SIDA</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={Unicef} alt="" />
                  <p>Unicef</p>
                </div>
              </Zoom>

              <Zoom duration={2000}>
                <div className="singal_wrapper">
                  <img src={WorldVision} alt="" />
                  <p>World Vision Bangladesh</p>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CurrentDonor;
