import React from "react";
import { useEffect } from "react";
import "../../../style/about/legalAffiliation.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFeather } from "@fortawesome/free-solid-svg-icons";
import SocialMedia from "../../SocialMedia/SocialMedia";

const LegalAffiliation = () => {
  useEffect(() => {
    document.title = `Bnks | Affiliation`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/affiliation";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="affiliation_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Affiliation Part */}
        <div className="affiliation_wrapper">
          <div className="container">
            <h2>Origin & Legal Affiliation</h2>
            <div className="content_wrapper">
              <p>
                BNKS was founded in March 3, 1991 by Ma Lung Ching to give women
                of Bolipara a voice and with the vision to improve the life and
                empower the indigenous women. A women’s group was formed of 20
                members who came together to talk about the situation of
                Bolipara. The beginning programmes of BNKS worked with the
                Department of Women’s Welfare and Department of Youth Welfare to
                train Traditional Birth Attendants in safe and hygienic birth
                delivery.
              </p>
              <p className="my-2">
                BNKS appears as a NGO in July 1995 after getting registration
                from Department of Women Affairs.
              </p>
              <p>
                In July 19, 2001 BNKS gets registration from NGOAB and keep
                continue serve the activities by partnership to achieve goal.
              </p>
            </div>
          </div>
        </div>

        {/* Legal Affiliation Part */}
        <div className="legal_wrapper">
          <div className="container">
            <h2>Legal Status</h2>
            <div className="content_wrapper">
              <div className="single_part">
                <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                <p>Department of Women Affairs # MoBA/B.Ban/07/95 [view]</p>
              </div>
              <div className="single_part">
                <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                <p>Bureau of NGO Affairs # 1656-19-08-2001 [View]</p>
              </div>
              <div className="single_part">
                <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                <p>PADORE #BD-2016-FTS-2806808046-2</p>
              </div>
              <div className="single_part">
                <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                <p>DUNS # 731661661-2019</p>
              </div>
              <div className="single_part">
                <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                <p>TIN # 476978837076/Circle-25, Dated: 16/11/2022 (1 year)</p>
              </div>
            </div>
          </div>
        </div>

        {/* Others Affiliation Part */}
        <div className="others_affiliation_wrapper">
          <div className="container">
            <h2>Others Affiliation with Networks</h2>
            <div className="content_wrapper">
              <div className="national_network">
                <h3>National Network</h3>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Member of Camping for Popular Education (CAMPE)</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Member of Nari Jogajok Kendra (NJK)</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>
                    Member of Association for Land Reform Development (ALRD)
                  </p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Member of the Democratic Budget Movement (DBM)</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Member of Jatiyo Nari Nirzaton Protirodh Forum (JNNPF)</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Executive Committee at Food Security Network (Khani)</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Executive Member of START FUND</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>General Member of Volunteer Service Organization (VSO)</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Member of BROTI and</p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Member of NGOs Platform</p>
                </div>
              </div>

              <div className="international_network">
                <h3>International Network</h3>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>
                    Member of Global Network of Civil Society Organization for
                    Disaster Reduction (GNDR)
                  </p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>
                    Member of International Union Conservation for Nature (IUCN)
                  </p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>
                    Member of Indigenous People Organization-IPOs (Global
                    Network) and
                  </p>
                </div>
                <div className="single_part">
                  <FontAwesomeIcon className="affair_icon" icon={faFeather} />
                  <p>Member of Asia Shelter Forum (ASF)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LegalAffiliation;
