import React from "react";
import { useEffect } from "react";
import "../../../style/about/organogram.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import organogram from "../../../images/Organogram-bnks.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const Organogram = () => {
  useEffect(() => {
    document.title = `Bnks | Organogram`;
  }, []);

  const shareUrl = "https://bnksbd.org/human-resources/organogram";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="organogram_section">
        {/* Header Background Part Start */}
        <div className="header_background">
          <div className="container wrapper">
            <h2> Organogram </h2>
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Header Background Part End */}

        {/* Organogram Part */}
        <div className="organogram_wrapper">
          <div className="container">
            <h2>Organogram</h2>
            <img src={organogram} alt="" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Organogram;
