import React from "react";
import { useEffect } from "react";
import "../../../style/goalObjectivePage/health.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import Comment from "../../shared/Comment/Comment";

const Health = () => {
  useEffect(() => {
    document.title = `Bnks | Health`;
  }, []);

  const shareUrl = "https://bnksbd.org/health";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="health_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Health Part */}
        <div className="health_wrapper">
          <h3>Health Awareness</h3>
          <div className="content_wrapper">
            <div className="container">
              <p>
                The community people especially women and children have access
                to primary health service and that their knowledge on health
                care services are increased. Rural people of Bandarban Hill
                District do not have access to health services due to the
                remoteness and landscape of the area and difficulties in
                communication. Many health facilities and services are derelict
                and un-manned due to this people are not aware of basic health
                services that should be available to them.
              </p>
            </div>
          </div>

          <Comment />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Health;
