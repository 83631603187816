import React, { useState } from "react";
import { useEffect } from "react";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import "./../../../style/allActivity/activityOne.css";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import Comment from "../../shared/Comment/Comment";
import TranslateButton from "../../TranslateButton/TranslateButton";
import { useParams } from "react-router-dom";

const ActivityOne = () => {
  const [blogs, setBlogs] = useState([]);

  const { id } = useParams();
  const getBlogs = async () => {
    const response = await fetch(`https://admin.bnksbd.org/post/${id}`);
    setBlogs(await response.json());
  };

  useEffect(() => {
    getBlogs();
  }, [id]);

  useEffect(() => {
    document.title = `A meeting between vendors and bnks procurement committee`;
  }, []);

  const shareUrl =
    "https://bnksbd.org/a-meeting-between-vendors-and-bnks-procurement-committee";

  return (
    <>
      <Navbar />
      <SocialMedia />
      <TranslateButton />

      <div className="activity_one_section">
        <div className="activity_title">
          <div className="container">
            <h6>{blogs.title} </h6>
            <h2> {blogs.title}</h2>
            <h3>{blogs.date}</h3>
          </div>
        </div>
        <div className="image_part">
          <img src={blogs.img_url} alt="" />
          {/* Social Share Section Start */}
          <div className="social_share_wrapper">
            <div className="container">
              <div className="social_share_button">
                <FacebookShareButton
                  url={shareUrl}
                  target={"_blank"}
                  quote={"share facebook"}
                  hashtag={"#facebook"}
                >
                  <FacebookIcon size={35} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  target={"_blank"}
                  quote={"share twitter"}
                  hashtag={"#twitter"}
                >
                  <TwitterIcon size={35} round={true} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={shareUrl}
                  target={"_blank"}
                  quote={"share linkedin"}
                  hashtag={"#linkedin"}
                >
                  <LinkedinIcon size={35} round={true} />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          {/* Social Share Section End */}
          <p>{blogs.title}</p>
        </div>
        <div className="content_wrapper">
          <div className="container">
            <p>{blogs.description}</p>
            <button>
              <a
                href="/all-activity"
                target={"_blank"}
                id="style-2"
                data-replace="View More"
              >
                <span>View More</span>
              </a>
            </button>
          </div>
        </div>

        <Comment />
      </div>

      <Footer />
    </>
  );
};

export default ActivityOne;
