import React from "react";
import { useEffect } from "react";
import "../../../style/goalObjectivePage/livelihood.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import Comment from "../../shared/Comment/Comment";

const Livelihood = () => {
  useEffect(() => {
    document.title = `Bnks | Livelihood`;
  }, []);

  const shareUrl = "https://bnksbd.org/livelihood";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="livelihood_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Livelihood Part */}
        <div className="livelihood_wrapper">
          <h3>Livelihood</h3>
          <div className="content_wrapper">
            <div className="container">
              <p>
                Community peoples’ participation in profitable income is
                increased and their overall economic status is improved. The
                main occupation of the people is Jhum cultivation (slash and
                burn cultivation) however this practice is often for subsistence
                farming. Due to lack of communication infrastructure it is
                difficult for rural people to sell their produced products at
                market and often results in dealing with unnecessary middle men.
              </p>
            </div>
          </div>

          <Comment />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Livelihood;
