import React, { useEffect } from "react";
import "../../../style/activity.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

const Activity = () => {
  const [blogs, setBlogs] = useState([]);

  const { id } = useParams();
  // console.log(id);
  const getBlogs = async () => {
    const response = await fetch(`https://admin.bnksbd.org/posts`);
    setBlogs(await response.json());
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const threeBlogs = blogs.slice(0, 3);

  // This is for max 10 word in title
  const cutTextTo30Words = (text) => {
    const words = text.split(" ");
    const slicedWords = words.slice(0, 10);
    return slicedWords.join(" ");
  };

  return (
    <div className="activity_section">
      <h2>Our Activity</h2>
      <div className="container">
        <div className="activity_image_wrapper">
          {threeBlogs.map((item) => {
            return (
              <Link to={`/activity/${item.id}`} target={"_blank"}>
                <div key={item.id} className="single_wrapper">
                  <div className="image_wrapper">
                    <p className="activity">Blog</p>
                    <div className="image_wrapper">
                      <img src={item.img_url} alt="" />
                    </div>
                  </div>
                  <div className="image_title">
                    <p>{item.date}</p>
                    <h5>{cutTextTo30Words(item.title)}</h5>
                  </div>
                  <button>
                    <Link to={`/activity/${item.id}`} target={"_blank"}>
                      Read More
                      <FontAwesomeIcon
                        className="arrow_icon"
                        icon={faArrowRightLong}
                      />
                    </Link>
                  </button>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Activity;
