import React from "react";
import Navbar from "../../shared/Navbar/Navbar";
import Footer from "../../shared/Footer/Footer";
import TranslateButton from "../../TranslateButton/TranslateButton";
import "../../../style/about/missionAndVision.css";
import { useEffect } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faGraduationCap,
  faHandBackFist,
  faNotesMedical,
  faUniversalAccess,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import SocialMedia from "../../SocialMedia/SocialMedia";

const MissionAndVision = () => {
  useEffect(() => {
    document.title = `Bnks | Mission Vision`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/mission-vision";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="mission_vision_section">
        <div className="header_image"></div>

        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Vision part */}
        <div className="vision_wrapper">
          <div className="container">
            <div className="content_wrapper">
              <h2>Our Vision</h2>
              <h3>
                We believe women are entrepreneurs, change makers and leaders
              </h3>
              <p>
                BNKS envisions to ensure an enhanced standard of life for the
                people living in the country with especial emphasis on the CHTs
                and other parts of vulnerable areas through mainstreaming them
                to the current development paradigm of the country.
              </p>
            </div>
          </div>
        </div>

        {/* Mission part */}
        <div className="mission_wrapper">
          <div className="container">
            <h2>Our Mission</h2>
            <h3>
              Bnks is dedicated to the empowerment of poor women by creating
              strong economic and social resource bases.
            </h3>
            <p>
              To improve the social, economic and cultural status of the
              marginalized people of the Chittagong Hill Tract Community
              especially the women and children in a sustainable way through
              developing their knowledge, conscience, capacity and services
            </p>
          </div>
          <div className="content_wrapper"></div>
        </div>

        {/* Values Part */}
        <div className="values_wrapper">
          <div className="container">
            <div className="content_wrapper">
              <div>
                <h4>empowering</h4>
                <p>Inclusiveness and empowering </p>
              </div>
              <div>
                <h4>creativity</h4>
                <p>Innovation and creativity</p>
              </div>
              <div>
                <h4>Integrity</h4>
                <p> Integrity and openness </p>
              </div>
              <div>
                <h4>Effective</h4>
                <p>Effective and Efficiency</p>
              </div>
            </div>
          </div>
        </div>

        {/* Objective Part */}
        <div className="objective_wrapper">
          <div className="container">
            <h2>Goal And Objectives</h2>
            <div className="content_wrapper">
              <div className="objective_single_wrapper">
                <FontAwesomeIcon className="icon" icon={faNotesMedical} />
                <p>
                  The community people especially women and children have access
                  to primary health service and that their knowledge on health
                  care services are increased. Rural people of Bandarban Hill
                  District do not have access to health services due to the
                  remoteness and landscape of the area and difficulties in
                  communication. Many health facilities and services are
                  derelict and un-manned due to this people are not aware of
                  basic health services that should be available to them
                </p>
              </div>
              <div className="objective_single_wrapper">
                <FontAwesomeIcon className="icon" icon={faUniversalAccess} />
                <p>
                  Community people’s participation in profitable income is
                  increased and their overall economic status is improved. The
                  main occupation of the people is Jhum cultivation (slash and
                  burn cultivation) however this practice is often for
                  subsistence farming. Due to lack of communication
                  infrastructure it is difficult for rural people to sell their
                  produced products at market and often results in dealing with
                  unnecessary middle men
                </p>
              </div>
              <div className="objective_single_wrapper">
                <FontAwesomeIcon className="icon" icon={faGraduationCap} />
                <p>
                  All community people of Bandarban have access to education and
                  that their capacity and knowledge is improved through
                  education and training. The overall literacy level of
                  Bandarban is 39.5%, Men’s literacy is 56.22% and Women’s is at
                  23.5%. According to the communities extreme poverty hinders
                  the parents from sending their children to school. Families
                  prefer their children to engage in household and agricultural
                  works. Other problems include distance and education not in
                  mother tongue language
                </p>
              </div>
              <div className="objective_single_wrapper">
                <FontAwesomeIcon className="icon" icon={faHandBackFist} />
                <p>
                  All communities and local institutions know and practice their
                  basic rights. So that people and services able to develop to
                  empower the community to meet their basic needs
                </p>
              </div>
              <div className="objective_single_wrapper">
                <FontAwesomeIcon className="icon" icon={faUsers} />
                <p>
                  Local people of Bandarban are free to practice and reserve
                  their own cultures, values and customs. There are 13 different
                  ethnic groups that are co-habiting the area of Bandarban Hill
                  District. However due to extreme poverty people are unable to
                  practice their cultural traditions. Traditional cultures are
                  being mainstreamed into each other, values and customs are
                  being forgotten
                </p>
              </div>
              <div className="objective_single_wrapper">
                <FontAwesomeIcon className="icon" icon={faGlobe} />
                <p>
                  People and the environment are protected and living
                  sustainably alongside another. Community people of Bandarban
                  are facing many problems due to climate change and practices
                  that are affecting the local environment. The seasons play an
                  active role in the rural people’s life, especially farming
                  however the seasons are becoming more and more disrupted with
                  monsoon coming earlier and heavier each year. The area is
                  subject to landslides due to heavy rainfall in the wet season,
                  deforestation, forest fire and land reforming to create more
                  land for development
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MissionAndVision;
