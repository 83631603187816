import React from "react";
import { useEffect } from "react";
import "../../../style/resource/photoGallery.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import FirstImage from "../../../images/Project-image/1-Dialouge-Session-with-services-delivary-institutions-1-1024x461.jpg";
import SecondImage from "../../../images/Project-image/Taekwondo-3-2-1024x461.jpg";
import ThirdImage from "../../../images/Project-image/Public-Hearing-2-1-1024x682.jpg";
import ForthImage from "../../../images/Project-image/Public-Hearing-3-1-1024x682.jpg";
import FifthImage from "../../../images/Project-image/Khata-Distribution-2-1-1024x682.jpg";
import SixthImage from "../../../images/Project-image/20220201_110845-1024x768.jpg";
import SeventhImage from "../../../images/Project-image/20220130_103942-1024x768.jpg";
import EightImage from "../../../images/Project-image/Dialouge-With-National-prints-Media-1-1024x579.jpg";
import NinthImage from "../../../images/Project-image/Leagal-Aid-Coordination-Meeting-at-Union-level-1024x461.jpg";
import Ten from "../../../images/Project-image/1.jpg";
import Eleven from "../../../images/Project-image/2..jpg";
import Twelve from "../../../images/Project-image/3..jpg";
import Thirteen from "../../../images/Project-image/4..jpg";
import Forteen from "../../../images/Project-image/5..jpg";
import Fifteen from "../../../images/Project-image/6..jpg";
import Sixteen from "../../../images/Project-image/6-two.jpg";
import Seventeen from "../../../images/Project-image/7..jpg";
import Eighteen from "../../../images/Project-image/8..jpg";
import Nineteen from "../../../images/Project-image/9..jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const PhotoGallery = () => {
  useEffect(() => {
    document.title = `Bnks | Photo Gallery`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/Photo-gallery";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="photo_gallery_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Photo Gallery Part */}
        <div className="photo_gallery_wrapper">
          <div className="container">
            <h2>Photo Gallery</h2>
            <div className="wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={FirstImage} alt="" />
                </div>
                <div className="text">
                  <p>Dialogue Session with services delivery institutions</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SecondImage} alt="" />
                </div>
                <div className="text">
                  <p>Taekwondo Training Participants</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ThirdImage} alt="" />
                </div>
                <div className="text">
                  <p>Public Hearing 2021</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ForthImage} alt="" />
                </div>
                <div className="text">
                  <p>Public Hearing</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={FifthImage} alt="" />
                </div>
                <div className="text">
                  <p>Khata Distribution to Sponsor Children</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SixthImage} alt="" />
                </div>
                <div className="text">
                  <p>Program Launching at Ramu, Cox’s Bazar</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SeventhImage} alt="" />
                </div>
                <div className="text">
                  <p>Design Workshop at Ramu, Cox’s Bazar</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={EightImage} alt="" />
                </div>
                <div className="text">
                  <p>Dialogue With National prints Media</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={NinthImage} alt="" />
                </div>
                <div className="text">
                  <p>Legal Aid Coordination Meeting at Union level</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Ten} alt="" />
                </div>
                <div className="text">
                  <p>
                    Distribution of Protective Equipment for Disaster Response
                    by Mr. Bir Bahadur U Shai Shing, MP, Honorable Minister,
                    Ministry of Hill Chittagong Affairs
                  </p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Eleven} alt="" />
                </div>
                <div className="text">
                  <p>
                    Shri Jyotindra Bodhipriya Larma Santu Larma, Honorable
                    Chairman of Chittagong Hill Tracts Regional Council
                  </p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Twelve} alt="" />
                </div>
                <div className="text">
                  <p>
                    Joint Secretary of the Ministry of Chittagong Hill Tracts
                    visited the working area of BNKS
                  </p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Thirteen} alt="" />
                </div>
                <div className="text">
                  <p>
                    Joint Secretary of the Ministry of Chittagong Hill Tracts
                    visited the working area of BNKS
                  </p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Forteen} alt="" />
                </div>
                <div className="text">
                  <p>
                    Honorable District Administrator Mr. Yasmin Parveen Tibriji
                    visited the activities under implementation of BNKS on
                    25/02/2021 and some pictures of it
                  </p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Fifteen} alt="" />
                </div>
                <div className="text">
                  <p>Sanitation Latrine Installation at the community level</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Sixteen} alt="" />
                </div>
                <div className="text">
                  <p>Sanitation Latrine Installation at the community level</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Seventeen} alt="" />
                </div>
                <div className="text">
                  <p>Project Inception program in Thanchi</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Eighteen} alt="" />
                </div>
                <div className="text">
                  <p>Free Medical Health Camp arranged by BNKS</p>
                </div>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={Nineteen} alt="" />
                </div>
                <div className="text">
                  <p>
                    {" "}
                    Monthly stipend supported to poor and vulnerable students
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PhotoGallery;
