import React from "react";
import "../../../style/header.css";
import image from "../../../images/Project-image/1-Dialouge-Session-with-services-delivary-institutions-1-1024x461.jpg";
import imageThree from "../../../images/Project-image/Dialouge-With-National-prints-Media-1-1024x579.jpg";
import imageFive from "../../../images/Project-image/Khata-Distribution-2-1-1024x682.jpg";
import imageSix from "../../../images/Project-image/Leagal-Aid-Coordination-Meeting-at-Union-level-1024x461.jpg";
import imageSeven from "../../../images/Project-image/Public-Hearing-3-1-1024x682.jpg";
import imageNine from "../../../images/Project-image/Taekwondo-3-2-1024x461.jpg";
import imageTen from "../../../images/Project-image/20220201_110845-1024x768.jpg";
import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Header = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="header_image_section">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <div className="header_image">
            <img src={image} alt="" />
          </div>
          <Carousel.Caption className="content">
            <p>Dialogue Session with services delivery institutions</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={imageThree} alt="" />
          <Carousel.Caption className="content">
            <p>Dialogue With National prints Media</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={imageFive} alt="" />
          <Carousel.Caption className="content">
            <p>Khata Distribution to Sponsor Children</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={imageSix} alt="" />
          <Carousel.Caption className="content">
            <p>Legal Aid Coordination Meeting at Union level</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={imageSeven} alt="" />
          <Carousel.Caption className="content">
            <p>Public Hearing</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={imageNine} alt="" />
          <Carousel.Caption className="content">
            <p>Taekwondo Training Participants</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img src={imageTen} alt="" />
          <Carousel.Caption className="content">
            <p>Program Launching at Ramu, Cox’s Bazar</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Header;
