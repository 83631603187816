import React from "react";
import { useEffect } from "react";
import "../../../style/resource/awardOfBnks.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import AwardOne from "../../../images/Award/1.jpg";
import AwardTwo from "../../../images/Award/2.jpg";
import AwardThree from "../../../images/Award/10.jpg";
import AwardFour from "../../../images/Award/4.jpg";
import AwardFive from "../../../images/Award/5.jpg";
import AwardSix from "../../../images/Award/6.jpg";
import AwardSeven from "../../../images/Award/7.jpg";
import AwardEight from "../../../images/Award/8.jpg";
import AwardNine from "../../../images/Award/3.jpg";
import AwardTen from "../../../images/Award/9.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const AwardOfBnks = () => {
  useEffect(() => {
    document.title = `Bnks | Award Of BNKS`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/award-of-bnks";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="awardOf_bnks_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Award Of BNKS Main Part */}
        <div className="awardOf_bnks_wrapper">
          <div className="container">
            <h2>Award Of BNKS</h2>
            <div className="content_wrapper">
              <div className="award_wrapper">
                <img src={AwardOne} alt="" />
                <img src={AwardTwo} alt="" />
                <img src={AwardThree} alt="" />
                <img src={AwardFour} alt="" />
                <img src={AwardFive} alt="" />
                <img src={AwardSix} alt="" />
                <img src={AwardSeven} alt="" />
                <img src={AwardEight} alt="" />
                <img src={AwardNine} alt="" />
                <img src={AwardTen} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AwardOfBnks;
