import React, { useEffect } from "react";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faMobileRetro,
} from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import "../../../style/contact/contact.css";
import TranslateButton from "../../TranslateButton/TranslateButton";
import SocialMedia from "../../SocialMedia/SocialMedia";

const Contact = () => {
  useEffect(() => {
    document.title = `Bnks | Contact`;
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    e.target.reset();
  };

  const shareUrl = "https://bnksbd.org/contact";

  return (
    <div>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="contact_section">
        <div>
          <iframe
            title="google-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d107425.9087925097!2d92.18558927900571!3d22.22233096629641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30ad43b062db20ef%3A0x591a6c3de1ee9dc6!2sBNKS!5e0!3m2!1sen!2sbd!4v1698984292883!5m2!1sen!2sbd"
            width="100%"
            height="665px"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="container">
          {/* Social Share Section Start */}
          <div className="social_share_wrapper">
            <p>Share this page</p>
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
          {/* Social Share Section End */}

          <div className="content_wrapper">
            <div className="office_details">
              <h3>Contact Details</h3>
              <div className="home_address">
                <FontAwesomeIcon className="home_icons" icon={faLocationDot} />

                <div>
                  <h1>Address</h1>
                  <p>Shoiloshova, Balaghata, Bandarban, Bangladesh</p>
                </div>
              </div>
              <div className="email my-3">
                <FontAwesomeIcon className="email_icons" icon={faEnvelope} />
                <div>
                  <h1>Email</h1>
                  <p>bnks.ed@gmail.com</p>
                </div>
              </div>
              <div className="phone">
                <FontAwesomeIcon className="phone_icons" icon={faMobileRetro} />
                <div>
                  <h1>Phone</h1>
                  <p>+88 01556-742358</p>
                  <p className="my-1">+88 02333302906</p>
                  <p>+88 02333302979</p>
                </div>
              </div>

              <div className="connect mt-3 ml-7">
                <div></div>
                <div>
                  <h1 className="mb-3">Connect</h1>
                  <div className="social_media_icons_wrap">
                    <a
                      href="http://www.youtube.com"
                      target="_blank"
                      className="youtube"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=100092254814167&mibextid=ZbWKwL"
                      target="_blank"
                      className="facebook"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a
                      href="http://www.instagram.com"
                      target="_blank"
                      className="instagram"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a
                      href="http://www.twitter.com"
                      target="_blank"
                      className="twitter"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a
                      href="http://www.linkedin.com"
                      target="_blank"
                      className="linkedin"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="email_wrapper">
              <h3>Email Us</h3>
              <form onSubmit={sendEmail}>
                <div class="text-gray-100 mx-auto">
                  <div className="first_last_name">
                    <div className="input-form">
                      <input
                        type="text"
                        className="form-control h-12 border-none border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1"
                        placeholder="First name"
                        name="first-name"
                        required
                      />
                    </div>
                    <div className="input-form">
                      <input
                        type="text"
                        className="form-control h-12 border-none border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1"
                        placeholder="Last name"
                        name="last-name"
                        required
                      />
                    </div>
                  </div>

                  <div className="input-form my-6">
                    <input
                      type="text"
                      className="form-control h-12 border-none border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1"
                      placeholder="Subject"
                      name="subject"
                      required
                    />
                  </div>

                  <div className="text-area">
                    <textarea
                      className="form-control w-full p-4 h14 border-none border-slate-300 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-700 focus:ring-1"
                      id=""
                      rows="5"
                      placeholder="Message"
                      name="Message"
                      required
                    ></textarea>
                  </div>

                  <div className="mt-4">
                    <button type="submit" className="button">
                      Sign Up
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
