import React from "react";
import { useEffect } from "react";
import "../../../style/about/hrPolicy.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import ImageOne from "../../../images/hr-policy/1.jpg";
import ImageTwo from "../../../images/hr-policy/2.jpg";
import ImageThree from "../../../images/hr-policy/3.jpg";
import ImageFour from "../../../images/hr-policy/4.jpg";
import ImageFive from "../../../images/hr-policy/5.jpg";
import ImageSix from "../../../images/hr-policy/6.jpg";
import ImageSeven from "../../../images/hr-policy/7.jpg";
import ImageEight from "../../../images/hr-policy/8.jpg";
import ImageNine from "../../../images/hr-policy/9.jpg";
import ImageTen from "../../../images/hr-policy/10.jpg";
import ImageEleven from "../../../images/hr-policy/11.jpg";
import ImageTwelve from "../../../images/hr-policy/12.jpg";
import ImageThirtheen from "../../../images/hr-policy/13.jpg";
import ImageFortheen from "../../../images/hr-policy/14.jpg";
import ImageFiftheen from "../../../images/hr-policy/15.jpg";
import ImageSixtheen from "../../../images/hr-policy/16.jpg";
import ImageSeventheen from "../../../images/hr-policy/17.jpg";
import ImageEighteen from "../../../images/hr-policy/18.jpg";
import ImageNinetheen from "../../../images/hr-policy/19.jpg";
import ImageTwenty from "../../../images/hr-policy/20.jpg";
import ImageTwentyOne from "../../../images/hr-policy/21.jpg";
import ImageTwentyTwo from "../../../images/hr-policy/22.jpg";
import ImageTwentyThree from "../../../images/hr-policy/23.jpg";
import ImageTwentyFour from "../../../images/hr-policy/24.jpg";
import ImageTwentyFive from "../../../images/hr-policy/25.jpg";
import ImageTwentySix from "../../../images/hr-policy/26.jpg";
import ImageTwentySeven from "../../../images/hr-policy/27.jpg";

const HrPolicy = () => {
  useEffect(() => {
    document.title = `Bnks | HR Policy`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/hr-policy";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="hr_policy_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="hr_policy_wrapper">
          <div className="container">
            <h2>HR Policy</h2>
            <div className="content_wrapper">
              <img src={ImageOne} alt="" />
              <img src={ImageTwo} alt="" />
              <img src={ImageThree} alt="" />
              <img src={ImageFour} alt="" />
              <img src={ImageFive} alt="" />
              <img src={ImageSix} alt="" />
              <img src={ImageSeven} alt="" />
              <img src={ImageEight} alt="" />
              <img src={ImageNine} alt="" />
              <img src={ImageTen} alt="" />
              <img src={ImageEleven} alt="" />
              <img src={ImageTwelve} alt="" />
              <img src={ImageThirtheen} alt="" />
              <img src={ImageFortheen} alt="" />
              <img src={ImageFiftheen} alt="" />
              <img src={ImageSixtheen} alt="" />
              <img src={ImageSeventheen} alt="" />
              <img src={ImageEighteen} alt="" />
              <img src={ImageNinetheen} alt="" />
              <img src={ImageTwenty} alt="" />
              <img src={ImageTwentyOne} alt="" />
              <img src={ImageTwentyTwo} alt="" />
              <img src={ImageTwentyThree} alt="" />
              <img src={ImageTwentyFour} alt="" />
              <img src={ImageTwentyFive} alt="" />
              <img src={ImageTwentySix} alt="" />
              <img src={ImageTwentySeven} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HrPolicy;
