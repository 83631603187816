import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Link, useParams } from "react-router-dom";
import "../../../style/WhoWeAre.css";

const About = () => {
  const [blogs, setBlogs] = useState([]);

  const { id } = useParams();
  console.log(id);
  const getBlogs = async () => {
    const response = await fetch(`https://admin.bnksbd.org/posts`);
    setBlogs(await response.json());
  };

  useEffect(() => {
    getBlogs();
  }, []);

  const oneBlog = blogs.slice(0, 1);

  // This is for max 10 word in title
  const cutTextTo30Words = (text) => {
    const words = text.split(" ");
    const slicedWords = words.slice(0, 10);
    return slicedWords.join(" ");
  };

  return (
    <div className="about_section">
      <div className="container">
        <h2>Who We Are</h2>
        <div className="about_wrapper">
          <div className="content_wrapper">
            <Fade bottom duration={2000}>
              <div>
                <p>
                  BNKS is a NGO established in 1991 by a group of energetic
                  like-minded women social workers with a view to promote the
                  socio-economic and cultural status of the marginalised and
                  socially excluded peoples of the country specially focusing on
                  the Chittagong Hill Tracts of Bangladesh.
                </p>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div>
                <p className="my-4">
                  BNKS stresses emphasis on empowering women and children
                  through, education, capital formation, health, water,
                  sanitation provision and community capacity and local
                  institution building. BNKS implements demand driven problem
                  solving programs by integrating indigenous and modern
                  scientific knowledge and techniques which promote bottom up
                  and participatory development processes.
                </p>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div>
                <p>
                  BNKS works with all vulnerable people irrespective of race,
                  disability, age, gender and religion. BNKS believes in and
                  feels the need for solidarity and cooperation among all
                  classes of people as well as with the Government institutions
                  for achieving its goals and objectives.
                </p>
              </div>
            </Fade>
          </div>
          <div className="latest_update">
            <div className="update_text">
              <marquee
                className="marqueTool"
                behavior="scroll"
                direction="left"
              >
                <h3>Latest Updates</h3>
              </marquee>
            </div>

            {oneBlog.map((item) => {
              return (
                <Link to={`/activity/${item.id}`} target={"_blank"}>
                  <div>
                    <div className="image_wrapper">
                      <img src={item.img_url} alt="" />
                    </div>
                    <div className="linked">{cutTextTo30Words(item.title)}</div>
                    <button>
                      <Link
                        to={`/activity/${item.id}`}
                        target={"_blank"}
                        id="style-2"
                        data-replace="Read More"
                      >
                        <span>Read More</span>
                      </Link>
                    </button>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
