import React from "react";
import { useEffect } from "react";
import "../../../style/noticeBoard/jobCircular.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const JobCircular = () => {
  useEffect(() => {
    document.title = `Bnks | Job Circular`;
  }, []);

  const shareUrl = "https://bnksbd.org/notice-board/job-circular";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="job_circular_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Office Circular Part */}
        <div className="job_circular_wrapper">
          <div className="container">
            <h2>Job Circular</h2>
            <div className="content_wrapper">
              <p>Job Vacancy Circular – September 08, 2023</p>
              <div className="report">
                <p>
                  Partnership for Resilient livelihoods for CHT region (PRLC)
                  project
                </p>
                <a
                  href="/Job-Vacancy-Circular_BNKS-MJF-PRLC-Project-08.09.2023.pdf"
                  // download={"Job-Vacancy-Circular_BNKS-MJF-PRLC-Project-08.09.2023.pdf"}
                  target={"_blank"}
                  id="style-2"
                  data-replace="Download Pdf"
                >
                  <span> Download Pdf</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JobCircular;
