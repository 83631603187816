import React from "react";
import { useEffect } from "react";
import "../../../style/about/smt.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import Image from "../../../images/blank-image.png";
import HlaShingNu from "../../../images/Smt/Hla-shing-nue.jpg";
import UbanuMarma from "../../../images/Smt/Ubanu-marma.jpg";
import PeshalChakma from "../../../images/Smt/Peshal-chakma.jpg";
import KyaBaThoi from "../../../images/Smt/Kya-ba-thoy.jpg";
import AbaMongMarma from "../../../images/Smt/aba-mong-marma.jpg";
import MyaMyaShing from "../../../images/Smt/Mya-mya-shing.jpg";
import SaingSaingMarma from "../../../images/Smt/Saing-saing-a-marma.jpg";
import SharatKumarChakma from "../../../images/Smt/Sharat-kumar-chakma.jpg";
import VannusiamBawm from "../../../images/Smt/Vannusiam-bawm.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const Smt = () => {
  useEffect(() => {
    document.title = `Bnks | Senior Management Team`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/senior-management-team";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="smt_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Senior Management Team Part */}
        <div className="smt_wrapper">
          <div className="container">
            <h3>Senior Management Team (SMT)</h3>
            <p>
              According to BNKS SMT ToR, The Senior Management Team has been
              established as a mechanism of the highest decision-making body of
              BNKS, which is responsible for the strategic and operational
              leadership of BNKS. Subject to the setting strategy, policy, and
              procedures, this team is the principal mechanism for directing the
              business and decision-making in BNKS.
            </p>

            <div className="content_wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={HlaShingNu} alt="" />
                </div>
                <h4>Ms. Hla Shing Nu</h4>
                <h5>Executive Director</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={UbanuMarma} alt="" />
                </div>
                <h4>Mr. U banu Marma</h4>
                <h5>Deputy Executive Director</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={PeshalChakma} alt="" />
                </div>
                <h4>Mr. Peshal Chakma</h4>
                <h5>Program Director</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={KyaBaThoi} alt="" />
                </div>
                <h4>Mr. Kya Ba Thoi</h4>
                <h5>Program Manager</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={AbaMongMarma} alt="" />
                </div>
                <h4>Mr. Aba Mong Marma</h4>
                <h5>Finance Director</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={MyaMyaShing} alt="" />
                </div>
                <h4>Ms. Mya Mya Shing</h4>
                <h5>Finance Manager</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SaingSaingMarma} alt="" />
                </div>
                <h4>Ms. Saing Saing Marma</h4>
                <h5>Manager HR & Admin</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SharatKumarChakma} alt="" />
                </div>
                <h4>Mr. Sharat Kumar Chakma</h4>
                <h5>MEAL Manager</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={VannusiamBawm} alt="" />
                </div>
                <h4>Mr. Vannusiam Bawm</h4>
                <h5>MEAL Officer</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Smt;
