import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../../style/socialMedia.css";

const SocialMedia = () => {
  return (
    <div className="social_media_section">
      <div className="social_media_icons_wrapper">
        <a
          href="http://www.youtube.com"
          target="_blank"
          rel="noreferrer"
          className="youtube"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          href="https://www.facebook.com/bnks.ed"
          target="_blank"
          rel="noreferrer"
          className="facebook"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="http://www.instagram.com"
          target="_blank"
          rel="noreferrer"
          className="instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="http://www.twitter.com"
          target="_blank"
          rel="noreferrer"
          className="twitter"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href="https://www.linkedin.com/company/bolipara-nari-kalyan-somity/"
          target="_blank"
          rel="noreferrer"
          className="linkedin"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
