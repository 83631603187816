import React from "react";
import { useEffect } from "react";
import "../../../style/projects/ongoingProject.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const OngoingProject = () => {
  useEffect(() => {
    document.title = `Bnks | Ongoing Project`;
  }, []);

  const shareUrl = "https://bnksbd.org/projects/ongoing-project";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="ongoing_project_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Current Project Part */}
        <div className="ongoing_project_wrapper">
          <h2>Current Projects of BNKS</h2>
          <div className="single_project_wrapper">
            <div className="container">
              <h3>
                1. Organizing Ethnic Societies for Improved their Social Safety
                (OESISS) Project
              </h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Organizing Ethnic Societies for Improved their Social Safety
                    (OESISS)
                  </p>
                  <p>ActionAid Bangladesh</p>
                  <p>Itali and Mutual Trust Bank Limited Bangladesh</p>
                  <p>January 2019 to December 2023</p>
                  <p>Thanchi, Bandarban </p>
                  <p>6,742,450</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>2. Y-Moves Project</h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Y-Moves</p>
                  <p>Plan International Bangladesh</p>
                  <p>Swedish International Development Agency (SIDA)</p>
                  <p>December 2019 to May 2024</p>
                  <p>Bandarban Sadar</p>
                  <p>53,90,774</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                3. Ensuring Reproductive Health Rights and Gender equality for
                Ethnic Hilly Women Bandarban Project
              </h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Ensuring Reproductive Health Rights and Gender equality for
                    Ethnic Hilly Women Bandarban
                  </p>
                  <p>Diakonia Bangladesh</p>
                  <p>Swedish International Development Agency (SIDA)</p>
                  <p>January 2021 to December 2023</p>
                  <p>Bandarban Sadar & Thanchi</p>
                  <p>5,545,624</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>4. Ramu Area Program Project</h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Ramu Area Program</p>
                  <p>World Vision Bangladesh</p>
                  <p>South Korea & Canada</p>
                  <p>October 2021 to September 2024</p>
                  <p>Ramu, Cox’s Bazar</p>
                  <p>36,096,961</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                5. Socio-economic Development Initiative for the Poor Villagers
                in Thanchi Project
              </h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Socio-economic Development Initiative for the Poor Villagers
                    in Thanchi Project
                  </p>
                  <p>Bangladesh Steel Re-Rolling Mills Ltd. (BSRM)</p>
                  <p>Bangladesh Steel Re-Rolling Mills Ltd. (BSRM)</p>
                  <p>March 15, 2022 to March 14, 2025</p>
                  <p>Thanchi, Bandarban </p>
                  <p>18,349,834</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>6. BRAC Bridge NFPE Schools in CHT Project</h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>BRAC Bridge NFPE Schools in CHT</p>
                  <p>BRAC</p>
                  <p>BRAC</p>
                  <p>May 2023 to December 2023</p>
                  <p>Naikhyanchari, Bandarban</p>
                  <p>1,100,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                7. Introduction and establishment of Sanitation Marketing in the
                Chittagong Hill Tracts (CHT) regions of Rangamati and
                Khagrachari Project
              </h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Introduction and establishment of Sanitation Marketing in
                    the Chittagong Hill Tracts (CHT) regions of Rangamati and
                    Khagrachari
                  </p>
                  <p>International Development Enterprises (IDE)</p>
                  <p>
                    UNICEF Bangladesh and Ambassy of Switzerland in Bangladesh
                  </p>
                  <p>July 2023 to February 2025</p>
                  <p>Rangamati & Khagrachari </p>
                  <p>11,000,00 0</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>8. SUSIHL Project</h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>SUSIHL</p>
                  <p>ActionAid Bangladesh</p>
                  <p>European Union</p>
                  <p>July 2023- December 2026 </p>
                  <p>Bandarban </p>
                  <p>125,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                9. Partnership for Resilient Livelihoods in CHT Region (PRLC)
                Project
              </h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Partnership for Resilient Livelihoods in CHT Region (PRLC)
                  </p>
                  <p>MJF</p>
                  <p>European Union</p>
                  <p>September 2023 to June 2026 </p>
                  <p>Thanchi, Bandarban </p>
                  <p>79,927,57 3</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>10. BRAC Bridge NFPE Schools in CHT Project</h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>BRAC Bridge NFPE Schools in CHT</p>
                  <p>BRAC</p>
                  <p>BRAC</p>
                  <p>May 2023 to December 2024</p>
                  <p>Naikhyanchari, Bandarban </p>
                  <p>2,200,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                11. Support to FDMN in Cox’s Bazar in Education and WASH Sector
                Project
              </h3>
              <div className="content_wrapper">
                <div className="serial_no">
                  <p className="bold_font">SL No</p>
                  <p>1</p>
                  <p>2</p>
                  <p>3</p>
                  <p>4</p>
                  <p>5</p>
                  <p>6</p>
                </div>
                <div className="particulars">
                  <p className="bold_font">Particulars</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Support to FDMN in Cox’s Bazar in Education and WASH Sector
                  </p>
                  <p>BRAC</p>
                  <p>
                    Global Affairs Canada (GAD) and Department of Foreign
                    Affairs and Trade (DFAT)
                  </p>
                  <p>September 2023 to August 2024</p>
                  <p>Camp – 24, Teknaf, Cox’s Bazar </p>
                  <p>33,08,535</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OngoingProject;
