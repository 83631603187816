import React from "react";
import { useEffect } from "react";
import "../../../style/resource/auditReport.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const AuditReport = () => {
  useEffect(() => {
    document.title = `Bnks | Audit Report`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/audit-report";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="audit_report_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Audit Report Part */}
        <div className="audit_report_wrapper">
          <h2>Audit Report</h2>
          <div className="content_wrapper">
            <div className="container">
              <div className="report_wrapper">
                <div className="report">
                  <p> Audit Report (July 2022 - July 2023)</p>
                  <a
                    href="/Audit Report-July 2022-June'2023.pdf"
                    // download={"Audit Report-July 2022-June'2023.pdf"}
                    target={"_blank"}
                    id="style-2"
                    data-replace="Download Pdf"
                  >
                    <span> Download Pdf </span>
                  </a>
                </div>

                <div className="report my-3">
                  <p> Audit Report (July 2021 - July 2022)</p>
                  <a
                    href="/Audit-report-July-2021-June2022-1.pdf"
                    // download={"Audit-report-July-2021-June2022-1.pdf"}
                    target={"_blank"}
                    id="style-2"
                    data-replace="Download Pdf"
                  >
                    <span> Download Pdf </span>
                  </a>
                </div>

                <div className="report">
                  <p> Audit Report (July 2020 - July 2021)</p>
                  <a
                    href="/Audit-report-July-2020-June2021.pdf"
                    // download={"Audit-report-July-2020-June2021.pdf"}
                    target={"_blank"}
                    id="style-2"
                    data-replace="Download Pdf"
                  >
                    <span> Download Pdf</span>
                  </a>
                </div>

                <div className="report my-3">
                  <p> Audit Report (July 2019 - July 2020)</p>
                  <a
                    href="/Audit-report-July-2019-June-2020.pdf"
                    // download={"Audit-report-July-2019-June-2020.pdf"}
                    target={"_blank"}
                    id="style-2"
                    data-replace="Download Pdf"
                  >
                    <span> Download Pdf </span>
                  </a>
                </div>

                <div className="report">
                  <p> Audit Report (July 2018 - July 2019)</p>
                  <a
                    href="/Audit-report-July-2018-June-2019.pdf"
                    // download={"Audit-report-July-2018-June-2019.pdf"}
                    target={"_blank"}
                    id="style-2"
                    data-replace="Download Pdf"
                  >
                    <span> Download Pdf</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AuditReport;
