import React, { useEffect } from "react";
import Footer from "../shared/Footer/Footer";
import Navbar from "../shared/Navbar/Navbar";
import "../../style/donation.css";
import TranslateButton from "../TranslateButton/TranslateButton";

const Donation = () => {
  useEffect(() => {
    document.title = `BNKS | Donation`;
  }, []);

  return (
    <>
      <Navbar />
      <TranslateButton />

      <div className="donation_section">
        <div className="container">
          <h2>Donation</h2>
          <div className="content_wrapper">
            <div className="bank_information">
              <h4>Bank Information</h4>
              <p>
                <b>Bank Name :</b> Agrani Bank Limited
              </p>
              <p>
                <b>Account Name :</b> Bolipara Nari Kalyan Somity
              </p>
              <p>
                <b>Account No :</b> 0200002402187
              </p>
              <p>
                <b>Account Type :</b> Saving
              </p>
              <p>
                <b>Swift Code :</b> AGBKBDDH
              </p>
              <p>
                <b>Branch Code :</b> 6630
              </p>
              <p>
                <b>Branch Name :</b> Bandarban Branch, Bandarban
              </p>
            </div>
            <div className="bKash_information">
              <h4>bKash Information</h4>
              <p>
                <b>bKash Personal :</b> 01556742358
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Donation;
