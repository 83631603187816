import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/app.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Notfound from "./components/shared/Notfound/Notfound";
import Donation from "./components/pages/Donation";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Contact from "./components/pages/Contact/Contact";
import MissionAndVision from "./components/pages/About/MissionAndVision.";
import InterventionPrinciples from "./components/pages/About/InterventionPrinciples";
import LegalAffiliation from "./components/pages/About/LegalAffiliation";
import PayrollManagement from "./components/pages/Resources/PayrollManagement";
import Recruitment from "./components/pages/Resources/Recruitment";
import TrainingManagement from "./components/pages/Resources/TrainingManagement";
import AdministrativeManagement from "./components/pages/Resources/AdministrativeManagement";
import Procurement from "./components/pages/Resources/Procurement";
import ItCommunication from "./components/pages/Resources/ItCommunication";
import Infrastructures from "./components/pages/Resources/Infrastructures";
import ContractManagement from "./components/pages/Resources/ContractManagement";
import BudgetManagement from "./components/pages/Resources/BudgetManagement";
import CashManagement from "./components/pages/Resources/CashManagement";
import AnnualReport from "./components/pages/Resources/AnnualReport";
import AuditReport from "./components/pages/Resources/AuditReport";
import PhotoGallery from "./components/pages/Resources/PhotoGallery";
import CurrentProject from "./components/pages/Projects/OngoingProject";
import CompleteProject from "./components/pages/Projects/CompleteProject";
import AllActivity from "./components/pages/AllActivity/AllActivity";
import ActivityOne from "./components/pages/AllActivity/ActivityOne";
import Health from "./components/pages/GoalObjectivePages/Health";
import Livelihood from "./components/pages/GoalObjectivePages/Livelihood";
import Education from "./components/pages/GoalObjectivePages/Education";
import Rights from "./components/pages/GoalObjectivePages/Rights";
import Culture from "./components/pages/GoalObjectivePages/Culture";
import Climate from "./components/pages/GoalObjectivePages/Climate";
import Organogram from "./components/pages/About/Organogram";
import UpcomingEvent from "./components/pages/NoticeBoard/UpcomingEvent";
import OfficeCircular from "./components/pages/NoticeBoard/OfficeCircular";
import JobCircular from "./components/pages/NoticeBoard/JobCircular";
import MessageFromEd from "./components/pages/About/MessageFromEd";
import BnksBackground from "./components/pages/About/BnksBackground";
import BnksProfile from "./components/pages/About/BnksProfile";
import GeneralCouncil from "./components/pages/About/GeneralCouncil";
import AdvisoryBoard from "./components/pages/About/AdvisoryBoard";
import ExecutiveCouncil from "./components/pages/About/ExecutiveCouncil";
import Smt from "./components/pages/About/Smt";
import ConsultingAgency from "./components/pages/About/ConsultingAgency";
import Constitution from "./components/pages/About/Constitution";
import AuditSystem from "./components/pages/About/AuditSystem";
import Ngoab from "./components/pages/Affiliation/Ngoab";
import SocialWelfare from "./components/pages/Affiliation/SocialWelfare";
import Pador from "./components/pages/Affiliation/Pador";
import SamAndDuns from "./components/pages/Affiliation/SamAndDuns";
import AdbCms from "./components/pages/Affiliation/AdbCms";
import JoinStock from "./components/pages/Affiliation/JoinStock";
import TinBin from "./components/pages/Affiliation/TinBin";
import FocusArea from "./components/pages/FocusArea/FocusArea";
import StrategicPlan from "./components/pages/FocusArea/StrategicPlan";
import ThematicIssue from "./components/pages/FocusArea/ThematicIssue";
import ProjectCoverage from "./components/pages/Projects/ProjectCoverage";
import CurrentDonor from "./components/pages/Donor/CurrentDonor";
import PastDonor from "./components/pages/Donor/PastDonor";
import TendersNotice from "./components/pages/NoticeBoard/TendersNotice";
import QuotationNotice from "./components/pages/NoticeBoard/QuotationNotice";
import TestResult from "./components/pages/NoticeBoard/TestResult";
import ShortListed from "./components/pages/NoticeBoard/ShortListed";
import FinalResult from "./components/pages/NoticeBoard/FinalResult";
import AdminTeam from "./components/pages/Resources/AdminTeam";
import FinanceTeam from "./components/pages/Resources/FinanceTeam";
import ComplianceUnit from "./components/pages/Resources/ComplianceUnit";
import MonitoringUnit from "./components/pages/Resources/MonitoringUnit";
import VendorList from "./components/pages/Resources/VendorList";
import AwardOfBnks from "./components/pages/Resources/AwardOfBnks";
import ProfessionalVolunter from "./components/pages/Resources/ProfessionalVolunter";
import SupportPartner from "./components/pages/Resources/SupportPartner";
import Factsheet from "./components/pages/Resources/Factsheet";
import ChildProtectionPolicy from "./components/pages/About/ChildProtectionPolicy";
import CodeOfConduct from "./components/pages/About/CodeOfConduct";
import ConflictOfInterest from "./components/pages/About/ConflictOfInterest";
import EmergencyResponsePolicy from "./components/pages/About/EmergencyResponsePolicy";
import EnvironmentPolicy from "./components/pages/About/EnvironmentPolicy";
import FinancialManagementPolicy from "./components/pages/About/FinancialManagementPolicy";
import GeneralAudit from "./components/pages/About/GeneralAudit";
import GenderPolicy from "./components/pages/About/GenderPolicy";
import HrPolicy from "./components/pages/About/HrPolicy";
import MoneyLaunderingPolicy from "./components/pages/About/MoneyLaunderingPolicy";
import SafeguardingSecurityPolicy from "./components/pages/About/SafeguardingSecurityPolicy";
import ProtectionAndSexualExploitation from "./components/pages/About/ProtectionAndSexualExploitation";
import SecurityPolicy from "./components/pages/About/SecurityPolicy";
import TravelPolicy from "./components/pages/About/TravelPolicy";
import WhistleBlowingPolicy from "./components/pages/About/WhistleBlowingPolicy";
import IncomeTax from "./components/pages/Affiliation/IncomeTax";
import BankCertificate from "./components/pages/Affiliation/BankCertificate";
import NetworkAffiliation from "./components/pages/Affiliation/NetworkAffiliation";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/donation" element={<Donation />} />
        <Route path="/activity/:id" element={<ActivityOne />} />
        <Route path="/all-activity" element={<AllActivity />} />
        <Route path="/health" element={<Health />} />
        <Route path="/livelihood" element={<Livelihood />} />
        <Route path="/education" element={<Education />} />
        <Route path="/rights" element={<Rights />} />
        <Route path="/culture" element={<Culture />} />
        <Route path="/climate" element={<Climate />} />

        {/* About */}
        <Route path="/about/message-from-ed" element={<MessageFromEd />} />
        <Route path="/about/bnks-background" element={<BnksBackground />} />
        <Route path="/about/mission-vision" element={<MissionAndVision />} />
        <Route path="/about/bnks-profile" element={<BnksProfile />} />
        <Route path="/about/organogram" element={<Organogram />} />
        <Route path="/about/general-council" element={<GeneralCouncil />} />
        <Route path="/about/executive-council" element={<ExecutiveCouncil />} />
        <Route path="/about/advisory-board" element={<AdvisoryBoard />} />
        <Route path="/about/senior-management-team" element={<Smt />} />
        <Route path="/about/consulting-agency" element={<ConsultingAgency />} />
        <Route path="/about/constitution" element={<Constitution />} />
        <Route path="/about/audit-system" element={<AuditSystem />} />

        <Route
          path="/about/intervention-principles"
          element={<InterventionPrinciples />}
        />
        <Route path="/about/legal-affiliation" element={<LegalAffiliation />} />
        <Route
          path="/about/child-protection-policy"
          element={<ChildProtectionPolicy />}
        />
        <Route
          path="/about/code-of-conduct-policy"
          element={<CodeOfConduct />}
        />
        <Route
          path="/about/conflict-of-interest-policy"
          element={<ConflictOfInterest />}
        />
        <Route
          path="/about/emergency-response-policy"
          element={<EmergencyResponsePolicy />}
        />
        <Route
          path="/about/environment-policy"
          element={<EnvironmentPolicy />}
        />
        <Route
          path="/about/financial-management-policy"
          element={<FinancialManagementPolicy />}
        />
        <Route path="/about/general-audit-tool" element={<GeneralAudit />} />
        <Route path="/about/gender-policy" element={<GenderPolicy />} />
        <Route path="/about/hr-policy" element={<HrPolicy />} />
        <Route
          path="/about/money-laundering-terrorist-financial-policy"
          element={<MoneyLaunderingPolicy />}
        />
        <Route
          path="/about/safeguarding-behavioral-security-policy"
          element={<SafeguardingSecurityPolicy />}
        />
        <Route
          path="/about/protection-from-sexual-exploitation-and-abuse"
          element={<ProtectionAndSexualExploitation />}
        />
        <Route path="/about/security-policy" element={<SecurityPolicy />} />
        <Route path="/about/travel-policy" element={<TravelPolicy />} />
        <Route
          path="/about/whistle-blowing-policy"
          element={<WhistleBlowingPolicy />}
        />

        {/* Affiliation */}
        <Route path="/affiliation/ngoab" element={<Ngoab />} />
        <Route
          path="/affiliation/women-affairs-certificate"
          element={<SocialWelfare />}
        />
        <Route
          path="/affiliation/income-tax-certificate"
          element={<IncomeTax />}
        />
        <Route path="/affiliation/pador" element={<Pador />} />
        <Route path="/affiliation/sam-and-duns" element={<SamAndDuns />} />
        <Route path="/affiliation/adb-cms" element={<AdbCms />} />
        <Route path="/affiliation/join-stock" element={<JoinStock />} />
        <Route
          path="/affiliation/tin-and-bin-certificate"
          element={<TinBin />}
        />
        <Route
          path="/affiliation/bank-certificate"
          element={<BankCertificate />}
        />
        <Route
          path="/affiliation/network-affiliation"
          element={<NetworkAffiliation />}
        />

        {/* Focus Area */}
        <Route path="/focusArea/focus-area" element={<FocusArea />} />
        <Route path="/focusArea/strategic-plan" element={<StrategicPlan />} />
        <Route
          path="/focusArea/other-thematic-issue"
          element={<ThematicIssue />}
        />

        {/* Projects */}
        <Route path="/projects/ongoing-project" element={<CurrentProject />} />
        <Route
          path="/projects/complete-project"
          element={<CompleteProject />}
        />
        <Route
          path="/projects/project-coverage"
          element={<ProjectCoverage />}
        />

        {/* Donor */}
        <Route path="/donor/current-donors" element={<CurrentDonor />} />
        <Route path="/donor/past-donors" element={<PastDonor />} />

        {/* Resources */}
        <Route path="/resources/annual-report" element={<AnnualReport />} />
        <Route path="/resources/audit-report" element={<AuditReport />} />
        <Route
          path="/resources/administrative-management"
          element={<AdministrativeManagement />}
        />
        <Route
          path="/resources/payroll-management"
          element={<PayrollManagement />}
        />
        <Route path="/resources/recruitment" element={<Recruitment />} />
        <Route
          path="/resources/training-management"
          element={<TrainingManagement />}
        />
        <Route path="/resources/photo-gallery" element={<PhotoGallery />} />

        <Route path="/resources/procurement" element={<Procurement />} />
        <Route
          path="/resources/it-communication"
          element={<ItCommunication />}
        />
        <Route
          path="/resources/infrastructures"
          element={<Infrastructures />}
        />
        <Route
          path="/resources/contract-management"
          element={<ContractManagement />}
        />
        <Route
          path="/resources/budget-management"
          element={<BudgetManagement />}
        />
        <Route path="/resources/cash-management" element={<CashManagement />} />
        <Route path="/resources/admin-team" element={<AdminTeam />} />
        <Route path="/resources/finance-team" element={<FinanceTeam />} />
        <Route
          path="/resources/internal-control-and-audit-compliance-unit"
          element={<ComplianceUnit />}
        />
        <Route
          path="/resources/internal-monitoring-unit"
          element={<MonitoringUnit />}
        />
        <Route path="/resources/bnks-vendor-list" element={<VendorList />} />
        <Route path="/resources/award-of-bnks" element={<AwardOfBnks />} />
        <Route
          path="/resources/bnks-professional-volunter"
          element={<ProfessionalVolunter />}
        />
        <Route path="/resources/support-partner" element={<SupportPartner />} />
        <Route path="/resources/factsheet" element={<Factsheet />} />

        {/* Notice Board */}
        <Route
          path="/notice-board/upcoming-events"
          element={<UpcomingEvent />}
        />
        <Route
          path="/notice-board/office-circular"
          element={<OfficeCircular />}
        />
        <Route path="/notice-board/tender-notice" element={<TendersNotice />} />
        <Route
          path="/notice-board/quotation-notice"
          element={<QuotationNotice />}
        />
        <Route path="/notice-board/job-circular" element={<JobCircular />} />
        <Route
          path="/notice-board/written-test-result"
          element={<TestResult />}
        />
        <Route
          path="/notice-board/short-listed-candidates"
          element={<ShortListed />}
        />
        <Route
          path="/notice-board/interview-and-final-result-sheet"
          element={<FinalResult />}
        />

        {/* Contact */}
        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<Notfound />} />
      </Routes>
    </>
  );
}

export default App;
