import React from "react";
import { useEffect } from "react";
import "../../../style/noticeBoard/shortListed.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const ShortListed = () => {
  useEffect(() => {
    document.title = `Bnks | Short Listed Candidates `;
  }, []);

  const shareUrl = "https://bnksbd.org/notice-board/short-listed-candidates";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="short_listed_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Upcoming event Part */}
        <div className="short_listed_wrapper">
          <div className="container">
            <h2>Short Listed Candidates</h2>
            <div className="content_wrapper">
              <h3>Short list candidates are not updated yet</h3>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ShortListed;
