import React from "react";
import { useEffect } from "react";
import "../../../style/affiliation/incomeTax.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import IncomeTaxCertificate from "../../../images/Certificate/Income-TAX-Certificate.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const IncomeTax = () => {
  useEffect(() => {
    document.title = `Bnks | Income Tax Certificate `;
  }, []);

  const shareUrl = "https://bnksbd.org/affiliation/income-tax-certificate";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="income_tax_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        <div className="income_tax_wrapper">
          <div className="container">
            <h2>Income Tax Certificate</h2>
            <div className="content_wrapper">
              <img src={IncomeTaxCertificate} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default IncomeTax;
