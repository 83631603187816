import React from "react";
import { useEffect } from "react";
import "../../../style/about/generalCouncil.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import Image from "../../../images/blank-image.png";
import ProfDrMaYinNu from "../../../images/General-council/Prof-dr-ma-yin-nu.jpg";
import NemkimBawm from "../../../images/General-council/Nemkim-bawm.jpg";
import ParmitaChakma from "../../../images/General-council/Parmita-chakma.jpg";
import HlaShingNue from "../../../images/General-council/Hla-shing-nue.jpg";
import MumuRakhaine from "../../../images/General-council/Mumu-rakhaine.jpg";
import ShaNuChing from "../../../images/General-council/Sha-nu-ching.jpg";
import AchainChak from "../../../images/General-council/Achain-chak.jpg";
import SuiMraChingMarma from "../../../images/General-council/Sui-mra-ching-marma.jpg";
import DoliPrue from "../../../images/General-council/Doli-prue.jpg";
import ZoonChakma from "../../../images/General-council/Zoon-chakma.png";
import SweneChingMarma from "../../../images/General-council/Swene-ching-marma.jpg";
import MayYePrue from "../../../images/General-council/May-ye-prue.png";
import NiniUMarma from "../../../images/General-council/Nini-u-marma.jpg";
import MyaMyaShingMarma from "../../../images/General-council/Mya-mya-shing-marma.jpg";
import SaingSaingAMarma from "../../../images/General-council/Saing-saing-a-marma.jpg";
import NueYangMarma from "../../../images/General-council/Nue-yang-marma.jpg";
import UMayKhainMarma from "../../../images/General-council/U-may-khain-marma.jpg";
import NeliNath from "../../../images/General-council/Neli-nath.jpg";
import RoonRauMro from "../../../images/General-council/Roon-rau-mro.png";
import AMyaNueMarma from "../../../images/General-council/A-mya-nue-marma.jpg";
import NuMayPrueMarma from "../../../images/General-council/Nu-may-prue-marma.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const GeneralCouncil = () => {
  useEffect(() => {
    document.title = `Bnks | General Council`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/general-council";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="general_council_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* General Council Part */}
        <div className="general_council_wrapper">
          <div className="container">
            <h3>General Council</h3>
            <p>
              The General Council is the all-powerful authority of the
              organization. This council shall elect the Executive Committee by
              convening a general meeting where members of the executive
              committee shall be elected. The general council reserves the right
              to amend bylaws - make additions and deletions, approve the annual
              budget, and control annual income and expenditure. The general
              council reserves the right to bring a no-confidence motion against
              the executive committee for instances of activities that go
              against the aims and objectives of the organization.
            </p>
            <h6>The BNKS General Council compromised with 21 members</h6>

            <div className="content_wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={ProfDrMaYinNu} alt="" />
                </div>
                <h4>Ms. Prof. Dr. Ma Yin Nu</h4>
                <h5>Physician</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={NemkimBawm} alt="" />
                </div>
                <h4>Ms. Nemkim Bawm</h4>
                <h5>Social Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ParmitaChakma} alt="" />
                </div>
                <h4>Ms. Parmita Chakma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={HlaShingNue} alt="" />
                </div>
                <h4>Ms. Hla Shing Nue</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={MumuRakhaine} alt="" />
                </div>
                <h4>Ms. Mumu Rakhaine</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ShaNuChing} alt="" />
                </div>
                <h4>Ms. Sha Nu Ching</h4>
                <h5>Headman</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={AchainChak} alt="" />
                </div>
                <h4>Ms. Achain Chak</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SuiMraChingMarma} alt="" />
                </div>
                <h4>Ms. Sui Mra Ching Marma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={DoliPrue} alt="" />
                </div>
                <h4>Ms. Doli Prue</h4>
                <h5>Social Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ZoonChakma} alt="" />
                </div>
                <h4>Ms. Zoon Chakma</h4>
                <h5>Social Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SweneChingMarma} alt="" />
                </div>
                <h4>Ms. Swene Ching Marma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={MayYePrue} alt="" />
                </div>
                <h4>Ms. May Ye Prue</h4>
                <h5>Social Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={NiniUMarma} alt="" />
                </div>
                <h4>Ms. Nini U Marma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={MyaMyaShingMarma} alt="" />
                </div>
                <h4>Ms. Mya Mya Shing Marma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={SaingSaingAMarma} alt="" />
                </div>
                <h4>Ms. Saing Saing A Marma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={NueYangMarma} alt="" />
                </div>
                <h4>Ms. Nue Yang Marma</h4>
                <h5>Social Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={UMayKhainMarma} alt="" />
                </div>
                <h4>Ms. U May Khain Marma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={NeliNath} alt="" />
                </div>
                <h4>Ms. Neli Nath</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={RoonRauMro} alt="" />
                </div>
                <h4>Ms. Roon Rau Mro</h4>
                <h5>Social Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={AMyaNueMarma} alt="" />
                </div>
                <h4>Ms. A Mya Nue Marma</h4>
                <h5>Development Worker</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={NuMayPrueMarma} alt="" />
                </div>
                <h4>Ms. Nu May Prue Marma</h4>
                <h5>Development Worker</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default GeneralCouncil;
