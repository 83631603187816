import React from "react";
import { useEffect } from "react";
import "../../../style/about/executiveCouncil.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import Nemkim from "../../../images/Executive-Member/Nemkim-bawm.jpg";
import DrYinNu from "../../../images/Executive-Member/Prof-dr-ma-yin-nu.jpg";
import HlaShingNu from "../../../images/Executive-Member/Hla-shing-nue.jpg";
import ShaNueChing from "../../../images/Executive-Member/Sha-nue-ching.jpg";
import DoliPrue from "../../../images/Executive-Member/Doli-prue.jpg";
import ZoonChakma from "../../../images/Executive-Member/Zoon-chakma.jpg";
import MayYePrue from "../../../images/Executive-Member/May-ye-prue.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const ExecutiveCouncil = () => {
  useEffect(() => {
    document.title = `Bnks | Executive Council`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/executive-council";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="executive_council_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* General Council Part */}
        <div className="executive_council_wrapper">
          <div className="container">
            <h3>Executive Council</h3>
            <p>
              BNKS has three tiers of governance structures- General Committee,
              Executive Committee and Advisory Committee. The general assembly,
              comprised of 21 members is the main decision-making body of the
              organization. This committee meets once a year to formulate and
              amend policies, and form or reform Executive Committee of the
              organization. The executive committee is formed by the general
              committee through an Annual General Meeting. The total number of
              that elected executive members is seven. The positions within the
              executive members are Chairperson, Vice-Chairperson, Secretary,
              Treasurer, and three other Executive Members.
            </p>
            <h6>
              The Executive council shall consists of the following 7 member
              team
            </h6>

            <div className="top_singal_image_wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={Nemkim} alt="" />
                </div>
                <h4>Ms. Nemkim Bawm</h4>
                <h5>Chairperson</h5>
              </div>
            </div>

            <div className="content_wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={DrYinNu} alt="" />
                </div>
                <h4>Prof. Dr. Ma Yin Nu</h4>
                <h5>Co-Chairman</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={HlaShingNu} alt="" />
                </div>
                <h4>Ms. Hla Shing Nue</h4>
                <h5>Secretary</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ShaNueChing} alt="" />
                </div>
                <h4>Ms. Sha Nue Ching</h4>
                <h5>Treasurer</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={DoliPrue} alt="" />
                </div>
                <h4>Ms. Doli Prue</h4>
                <h5>Executive Member</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ZoonChakma} alt="" />
                </div>
                <h4>Ms. Zoon Chakma</h4>
                <h5>Executive Member</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={MayYePrue} alt="" />
                </div>
                <h4>Ms. May Ye Prue</h4>
                <h5>Executive Member</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ExecutiveCouncil;
