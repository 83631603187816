import React from "react";
import { useEffect } from "react";
import "../../../style/about/advisoryBoard.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import Image from "../../../images/blank-image.png";
import MayLungChing from "../../../images/Advisory-board/May-lung-ching.jpg";
import DrPuChawNue from "../../../images/Advisory-board/Dr-pu-chaw-nue.jpg";
import ShweNuePrue from "../../../images/Advisory-board/Shwe-nue-prue.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const AdvisoryBoard = () => {
  useEffect(() => {
    document.title = `Bnks | Advisory Board`;
  }, []);

  const shareUrl = "https://bnksbd.org/about/advisory-board";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="advisory_board_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* General Council Part */}
        <div className="Advisory_board_wrapper">
          <div className="container">
            <h3>Advisory Board</h3>
            <p>
              The Advisory Council is responsible for guiding the organization,
              responsible to give valuable recommendations, information, etc.
              This council shall advise the Executive Council to establish links
              with national and international donor organizations and improve
              relationships for the multi-sectoral development of the
              organization. The council shall take measures to bring the
              organization into a stable and normal condition when indiscipline
              is found inside the organization by the approval of the majority
              of the general council members.
            </p>
            <h6>The BNKS Advisory Council compromised with 3 members</h6>

            <div className="content_wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={MayLungChing} alt="" />
                </div>
                <h4>Ms. May Lung Ching</h4>
                <h5>Founder Member & Advisor</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={DrPuChawNue} alt="" />
                </div>
                <h4>Mr. Dr. Pu Chaw Nue</h4>
                <h5>Advisor</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ShweNuePrue} alt="" />
                </div>
                <h4>Mr. Shwe Nue Prue</h4>
                <h5>Advisor</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdvisoryBoard;
