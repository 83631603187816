import React from "react";
import { useEffect } from "react";
import "../../../style/resource/trainingManagement.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SocialMedia from "../../SocialMedia/SocialMedia";

const TrainingManagement = () => {
  const [isShowQuestionOne, setIsShowQuestionOne] = useState(false);
  const [isShowQuestionTwo, setIsShowQuestionTwo] = useState(false);
  const [isShowQuestionThree, setIsShowQuestionThree] = useState(false);
  const [isShowQuestionFour, setIsShowQuestionFour] = useState(false);
  const [isShowQuestionFive, setIsShowQuestionFive] = useState(false);
  useEffect(() => {
    document.title = `Bnks | Training Management`;
  }, []);

  const shareUrl = "https://bnksbd.org/human-resources/training-management";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="Training_management_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Payroll Management Part */}
        <div className="training_management_wrapper">
          <div className="container">
            <h2>Training management</h2>
            <div className="content_wrapper">
              <h3>FAQ For Training management</h3>
              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionOne(!isShowQuestionOne)}
                >
                  <h4>
                    Do all employee assessed during their time with the
                    organisation?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionOne && (
                  <div className="answer">
                    <p>Yes</p>
                    <p>Every Fiscal Year </p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionTwo(!isShowQuestionTwo)}
                >
                  <h4>Does the organisation have a training policy?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionTwo && (
                  <div className="answer">
                    <p>No</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionThree(!isShowQuestionThree)}
                >
                  <h4>Does the organisation train employees internally?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionThree && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFour(!isShowQuestionFour)}
                >
                  <h4>
                    Does the organisation offer employees external training?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFour && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFive(!isShowQuestionFive)}
                >
                  <h4>Does the training costs planned on budget?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFive && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TrainingManagement;
