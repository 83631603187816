import React from "react";
import { useEffect } from "react";
import "../../../style/resource/recruitment.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SocialMedia from "../../SocialMedia/SocialMedia";

const Recruitment = () => {
  const [isShowQuestionOne, setIsShowQuestionOne] = useState(false);
  const [isShowQuestionTwo, setIsShowQuestionTwo] = useState(false);
  const [isShowQuestionThree, setIsShowQuestionThree] = useState(false);
  const [isShowQuestionFour, setIsShowQuestionFour] = useState(false);
  const [isShowQuestionFive, setIsShowQuestionFive] = useState(false);
  const [isShowQuestionSix, setIsShowQuestionSix] = useState(false);
  const [isShowQuestionSeven, setIsShowQuestionSeven] = useState(false);
  useEffect(() => {
    document.title = `Bnks | Recruitment Management`;
  }, []);

  const shareUrl = "https://bnksbd.org/human-resources/recruitment-management";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="recruitment_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Payroll Management Part */}
        <div className="recruitment_wrapper">
          <div className="container">
            <h2>Recruitment</h2>

            <div className="recruitment_process_wrapper">
              <h3>The steps in the recruitment process</h3>
              <div className="process_wrapper">
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Approval of Staff/s recruitment by Management Committee</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>
                    Recruitment Committee takes effort according to recruitment
                    policy
                  </p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Job vacancies circular</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Application registration</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Application short listing</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Inform for interviewing</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Interview (Written and Viva test)</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Selection complete</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Selection list approval by Executive Director</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>Offer letter and Joining letter</p>
                </div>
                <div className="single_process">
                  <FontAwesomeIcon className="check_icon" icon={faCircle} />
                  <p>MOU/Agreement</p>
                </div>
              </div>
            </div>

            {/* Recruitment Part */}
            <div className="content_wrapper">
              <h3>FAQ For Recruitment</h3>
              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionOne(!isShowQuestionOne)}
                >
                  <h4>
                    Does the organisation use systematically jobs descriptions
                    for recruitments?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionOne && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionTwo(!isShowQuestionTwo)}
                >
                  <h4>
                    Are job vacancies automatically advertised both internally
                    and externally?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionTwo && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionThree(!isShowQuestionThree)}
                >
                  <h4>
                    Are job vacancies spread on external diffusion channel? If
                    yes, please precise which on “comments”.
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionThree && (
                  <div className="answer">
                    <p>Yes</p>
                    <p>
                      Job vacancies are spread through circular in Local News
                      Paper, Notice board, E-mail etc
                    </p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFour(!isShowQuestionFour)}
                >
                  <h4>
                    Does the organisation have a reference procedure which sets
                    out the recruitment process (various steps) and defining the
                    role of responsibility of each person involved? If so,
                    please attach the document.
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFour && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionFive(!isShowQuestionFive)}
                >
                  <h4>Are references systematically taken up?</h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionFive && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionSix(!isShowQuestionSix)}
                >
                  <h4>
                    Are recruitment care formalised and archived (interviews
                    reports, technical tests, reference report)?
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionSix && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>

              <div className="question_wrapper">
                <div
                  className="single_question"
                  onClick={() => setIsShowQuestionSeven(!isShowQuestionSeven)}
                >
                  <h4>
                    Does the organisation have an updated organisation chart? If
                    so, please specify the update frequency.
                  </h4>
                  <FontAwesomeIcon className="plus_icon" icon={faPlus} />
                </div>
                {isShowQuestionSeven && (
                  <div className="answer">
                    <p>Yes</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Recruitment;
