import React, { useEffect } from "react";
import "./translateButton.css";
// import English from "../../images/National-Flag/English.png";
// import Bangla from "../../images/National-Flag/Bangladesh.png";
// import Hindi from "../../images/National-Flag/India.png";
// import Chinese from "../../images/National-Flag/Chinese.png";
// import Japanese from "../../images/National-Flag/Japan.png";
// import France from "../../images/National-Flag/France.png";
// import Russia from "../../images/National-Flag/Russia.png";
// import Italy from "../../images/National-Flag/Italy.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
// import { useState } from "react";
const TranslateButton = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
    script.defer = true;
    document.body.appendChild(script);

    // Set the gtranslateSettings object
    window.gtranslateSettings = {
      default_language: "en",
      languages: ["en", "bn", "fr", "hi", "ja", "ko"],
      wrapper_selector: ".gtranslate_wrapper",
      switcher_horizontal_position: "left",
      switcher_vertical_position: "bottom",
    };
  }, []);

  return (
    <>
      <div className="gtranslate_wrapper"></div>

      {/* <div className="translate_section">
        <div className="container">
          <div className="gt_selected">
            <div className="icon_and_image" onClick={() => setIsShow(!isShow)}>
              <a href="#">
                <img src={English} alt="en" />
                English
              </a>
              {isShow ? (
                <FontAwesomeIcon className="arrow_icons" icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon className="arrow_icons" icon={faAngleDown} />
              )}
            </div>
          </div>
          {isShow && (
            <div className="gt_option">
              <a href="#">
                <img src={Bangla} alt="bn" /> Bengali
              </a>
              <a href="#">
                <img src={English} alt="en" /> English
              </a>
              <a href="#">
                <img src={Hindi} alt="hi" /> Hindi
              </a>
              <a href="#">
                <img src={Chinese} alt="zh-CN" /> Chinese
              </a>
              <a href="#">
                <img src={Japanese} alt="ja" /> Japanese
              </a>
              <a href="#">
                <img src={France} alt="fr" /> French
              </a>
              <a href="#">
                <img src={Russia} alt="ru" /> Russian
              </a>
              <a href="#">
                <img src={Italy} alt="it" /> Italian
              </a>
            </div>
          )}
        </div>
      </div> */}
    </>
  );
};

export default TranslateButton;
