import React from "react";
import { useEffect } from "react";
import "../../../style/affiliation/networkAffiliation.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const NetworkAffiliation = () => {
  useEffect(() => {
    document.title = `Bnks | Network Affiliation`;
  }, []);

  const shareUrl = "https://bnksbd.org/affiliation/network-affiliation";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="network_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Network affiliation Main Part */}
        <div className="network_wrapper">
          <div className="container">
            <h2>BNKS Network Affiliation</h2>
            <h5>
              The BNKS has a strong network and is actively engaged with
              different platforms at local, national, and international levels
              for proper linkage and intervention are the following
            </h5>
            <div className="content_wrapper">
              <h3>A List of national networks is shown below-</h3>
              <div className="title">
                <h4>SL</h4>
                <h4>Name of Network</h4>
                <h4>Enrollment Year</h4>
              </div>
              <div className="single">
                <p>1</p>
                <p>Member of Campaign for Popular Education (CAMPE) </p>
                <p>2017</p>
              </div>
              <div className="single">
                <p>2</p>
                <p>Member of Nari Jogajok Kendra (NJK) </p>
                <p>2021</p>
              </div>
              <div className="single">
                <p>3</p>
                <p>
                  Member of Association for Land Reform and Development (ALRD)
                </p>
                <p>2011</p>
              </div>
              <div className="single">
                <p>4</p>
                <p>Member of Democratic Budget Movement (DBM)</p>
                <p>2014</p>
              </div>
              <div className="single">
                <p>5</p>
                <p>Member of Jatiyo Nari Nirzaton Protirodh Forum (JNNPF)</p>
                <p>2019</p>
              </div>
              <div className="single">
                <p>6</p>
                <p>
                  Executive Committee Member of Food Security Network (Khani)
                </p>
                <p>2018</p>
              </div>
              <div className="single">
                <p>7</p>
                <p>
                  Member of National Alliance of Humanitarian Actors, Bangladesh
                  (NAHAB)
                </p>
                <p>2018</p>
              </div>
              <div className="single">
                <p>8</p>
                <p>Member of START FUND </p>
                <p>From June 16, 2019</p>
              </div>
              <div className="single">
                <p>9</p>
                <p>Member of BROTI</p>
                <p></p>
              </div>
              <div className="single">
                <p>10</p>
                <p>Member of NGOs Platform </p>
                <p>From June 12, 2022</p>
              </div>
              <div className="single">
                <p>11</p>
                <p>Member of Education Sector </p>
                <p>From September 04, 2023</p>
              </div>
            </div>

            <div className="content_wrapper mt-10">
              <h3>A List of international networks is shown below-</h3>
              <div className="title">
                <h4>SL</h4>
                <h4>Name of Network</h4>
                <h4>Enrollment Year</h4>
              </div>
              <div className="single">
                <p>1</p>
                <p>
                  Member of Global Network of Civil Society Organizations for
                  Disaster Reduction (GNDR)
                </p>
                <p>From 11/08/2022</p>
              </div>
              <div className="single">
                <p>2</p>
                <p>
                  Member of International Union for Conservation of Nature
                  (IUCN)
                </p>
                <p>From May 2006</p>
              </div>
              <div className="single">
                <p>3</p>
                <p>
                  Member of IUCN- Indigenous Peoples' Organizations (IPOs)
                  Global Network. Member Identification Number IP/24848
                </p>
                <p>From 2016</p>
              </div>
              <div className="single">
                <p>4</p>
                <p>Asia Shelter Forum (ASF) </p>
                <p>From 2015</p>
              </div>
              <div className="single">
                <p>5</p>
                <p>
                  Member of Protection Against Sexual Exploitation and Abuse
                  (PSEA) Network
                </p>
                <p>From June 26, 2023</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default NetworkAffiliation;
