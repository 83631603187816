import React from "react";
import { useEffect } from "react";
import "../../../style/resource/procurement.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faAngellist } from "@fortawesome/free-brands-svg-icons";
import SocialMedia from "../../SocialMedia/SocialMedia";

const Procurement = () => {
  useEffect(() => {
    document.title = `Bnks | Procurement`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/procurement";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="procurement_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Payroll Management Part */}
        <h2>Procurement Committee </h2>
        <div className="procurement_committee_wrapper">
          <div className="container">
            <h5>
              BNKS will form a Procurement Committee for individual donor
              program procurement to complete all the procurement undertaken for
              that specific program/project. The formation and combination of
              committee members are as under-
            </h5>
            <div className="content_wrapper">
              <div className="serial">
                <h4>SL</h4>
                <p>1</p>
                <p>2</p>
                <p>3</p>
                <p>4</p>
                <p>5</p>
              </div>
              <div className="designation">
                <h4>Designation – Position in the Committee</h4>
                <p>DED/PD</p>
                <p>Admin & Procurement Coordinator/Officer</p>
                <p>Program Manager</p>
                <p>Project Focal/ Project Head</p>
                <p>Subject Matter Specialist (Need-based)</p>
              </div>

              <div className="functions">
                <h4>Functions</h4>
                <p>Convenor</p>
                <p>Secretary</p>
                <p>Member</p>
                <p>Member</p>
                <p>Member</p>
              </div>
            </div>
          </div>
        </div>

        <div className="procurement_wrapper">
          <div className="container">
            <div className="content_wrapper">
              <h3>
                The pattern of purchase validation (process, departments
                involved)
              </h3>
              <>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Budget</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Meeting minutes</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Requisition</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Checked, review and recommendation</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Approval for purchase</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Bill, Challan</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>GRN and Stock register</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Master roll, report with pictures</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Approval for payment</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>VAT/Tax etc</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Payment/settlement</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Voucher (entry in Tally)</p>
                </div>
                <div className="single_star">
                  <FontAwesomeIcon
                    className="stroke_icon"
                    icon={faCircleHalfStroke}
                  />
                  <p>Approve and archiving</p>
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="prosurement_title">
          <div className="container">
            <FontAwesomeIcon className="stroke_icon" icon={faAngellist} />
            <p>
              BNKS purchase according to need basis. A suppliers should have
              trade valid licence, papers documents, vat & tax, capacity of
              business and quality materials overall satisfactory services.At
              the time of a purchase, Quotation of goods price collection from
              minimum three vendors.
            </p>
          </div>
        </div>

        <div className="procedure_contract">
          <div className="container">
            <h3>BNKS follows procedures in every contract</h3>
            <div className="content_wrapper">
              <div className="single_contract">
                <FontAwesomeIcon
                  className="stroke_icon"
                  icon={faCircleHalfStroke}
                />
                <p>
                  Valid trade license, papers documents, vat & tax, the capacity
                  of business and quality materials overall satisfactory
                  services
                </p>
              </div>
              <div className="single_contract">
                <FontAwesomeIcon
                  className="stroke_icon"
                  icon={faCircleHalfStroke}
                />
                <p>Agreement</p>
              </div>
              <div className="single_contract">
                <FontAwesomeIcon
                  className="stroke_icon"
                  icon={faCircleHalfStroke}
                />
                <p>
                  Each responsible person do archive the purchase files at
                  account & admin section
                </p>
              </div>
              <div className="single_contract">
                <FontAwesomeIcon
                  className="stroke_icon"
                  icon={faCircleHalfStroke}
                />
                <p>How are purchase files classified?</p>
              </div>
              <div className="single_contract">
                <FontAwesomeIcon
                  className="stroke_icon"
                  icon={faCircleHalfStroke}
                />
                <p>BNKS do classify the purchase files as per project wise</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Procurement;
