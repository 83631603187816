import React from "react";
import { useEffect } from "react";
import "../../../style/resource/contractManagement.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const ContractManagement = () => {
  useEffect(() => {
    document.title = `Bnks | Contract Management`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/contract-management";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="contract_management_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Payroll Management Part */}
        <div className="contract_management_wrapper">
          <div className="container">
            <h2>Contracts Management</h2>
            <div className="content_wrapper">
              <h3>This page is a work in progress</h3>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContractManagement;
