import React from "react";
import { useEffect } from "react";
import "../../../style/goalObjectivePage/education.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";
import Comment from "../../shared/Comment/Comment";

const Education = () => {
  useEffect(() => {
    document.title = `Bnks | Education`;
  }, []);

  const shareUrl = "https://bnksbd.org/education";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="education_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Education Part */}
        <div className="education_wrapper">
          <h3>Education</h3>
          <div className="content_wrapper">
            <div className="container">
              <p>
                All community people of Bandarban have access to education and
                that their capacity and knowledge is improved through education
                and training. The overall literacy level of Bandarban is 39.5%,
                Men’s literacy is 56.22% and Women’s is at 23.5%. According to
                the communities extreme poverty hinders the parents from sending
                their children to school. Families prefer their children to
                engage in household and agricultural works. Other problems
                include distance and education not in mother tongue language.
              </p>
            </div>
          </div>

          <Comment />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Education;
