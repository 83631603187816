import React from "react";
import { useEffect } from "react";
import "../../../style/projects/completeProject.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const CompleteProject = () => {
  useEffect(() => {
    document.title = `Bnks | Complete Project`;
  }, []);

  const shareUrl = "https://bnksbd.org/projects/complete-project";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="complete_project_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Current Project Part */}
        <div className="complete_project_wrapper">
          <h2>Complete Projects of BNKS</h2>
          <div className="single_project_wrapper">
            <div className="container">
              <h3>1. Women Empowerment Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Women Empowerment</p>
                  <p>Oxfam GB</p>
                  <p>Oxfam GB</p>
                  <p>2001 to 2002</p>
                  <p>Thanchi, Bandarban </p>
                  <p>599,189</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>2. Primary Health Promotion Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Primary Health Promotion</p>
                  <p>Freedom Foundation </p>
                  <p>Freedom Foundation </p>
                  <p>2002 to 2004</p>
                  <p>Thanchi, Bandarban </p>
                  <p>1,831,882</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                3. Community Based water & Health Support for the Children of
                Thanchi Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Community Based water & Health Support for the Children of
                    Thanchi
                  </p>
                  <p>ActionAid Bangladesh </p>
                  <p>Action Aid Bangladesh </p>
                  <p>2004 to 2006</p>
                  <p>Thanchi, Bandarban </p>
                  <p>7,247,013</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                4. Drinking Water Supply, Sanitation and Income Generation for
                the Indigenous Hilly Community in Bandarban Hill District
                Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Drinking Water Supply, Sanitation and Income Generation for
                    the Indigenous Hilly Community in Bandarban Hill District
                  </p>
                  <p>NGO Forum for Public Health </p>
                  <p>Canadian International Development Agency (CIDA)</p>
                  <p>2005 (01 Year)</p>
                  <p>Thanchi, Bandarban </p>
                  <p>2,360,500</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                5. Environmental Sanitation, Hygiene and Water Supply in Urban
                Slums and Fringes Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Environmental Sanitation, Hygiene and Water Supply in Urban
                    Slums and Fringes
                  </p>
                  <p>GOB Unicef</p>
                  <p>UNICEF</p>
                  <p>2002 to 2006</p>
                  <p>Bandarban Sadar </p>
                  <p>880,920</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                6. Community Managed Water and Sanitation Programme for the
                Rural poor Communities in the Chittagong Hill Tracts (CMWSP-CHT)
                Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Community Managed Water and Sanitation Programme for the
                    Rural poor Communities in the Chittagong Hill Tracts
                    (CMWSP-CHT)
                  </p>
                  <p>NGO Forum For Drinking Water Supply and Sanitation</p>
                  <p>European Commission</p>
                  <p>2004 to 2006 </p>
                  <p>Bandarban Sadar </p>
                  <p>1,318,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                7. Urban Governance and Infrastructure Improvement Project
                (UGIIP)
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Urban Governance and Infrastructure Improvement Project
                    (UGIIP)
                  </p>
                  <p>Asian Development Bank (ADB)</p>
                  <p>ADB</p>
                  <p>2004 to 2006 </p>
                  <p>Bandarban Municipal </p>
                  <p>3,429,810</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                8. Non-Formal Primary Education Program Bangladesh Rural
                Advancement Committee Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Non-Formal Primary Education Program Bangladesh Rural
                    Advancement Committee
                  </p>
                  <p>BRAC </p>
                  <p>BRAC </p>
                  <p>July 2006 to December 2012 </p>
                  <p>Thanchi, Bandarban </p>
                  <p>1,304,879</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>9. Awareness on Water & Sanitation Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Awareness on Water & Sanitation</p>
                  <p>Bangladesh NGO Foundation </p>
                  <p>Government project </p>
                  <p>July 2008 to December 2011 </p>
                  <p>Thanchi, Bandarban </p>
                  <p>750,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                10. Poverty Reduction through Capacity Building for the Deprived
                People of Chittagong Hill Tracts Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Poverty Reduction through Capacity Building for the Deprived
                    People of Chittagong Hill Tracts
                  </p>
                  <p>Niwano Peace Foundation </p>
                  <p>Japan </p>
                  <p>April 2007 to March 2010 </p>
                  <p>Thanchi, Bandarban </p>
                  <p>1,803,929</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                11. Community Managed Water and Sanitation Programme in Rural
                Poor Communities of the Chittagong Hill Tracts (CMWSP-CHT) EC
                Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Community Managed Water and Sanitation Programme in Rural
                    Poor Communities of the Chittagong Hill Tracts (CMWSP-CHT)
                    EC
                  </p>
                  <p>NGO Forum For Drinking Water Supply and Sanitation </p>
                  <p>European Commission </p>
                  <p>July 2009 to December 2012 </p>
                  <p>Bandarban Sadar </p>
                  <p>1,523,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>12. CHT-HYSAWA Fund Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>CHT-HYSAWA Fund</p>
                  <p>GoB-DANIDA </p>
                  <p>DANIDA </p>
                  <p>2009 to 2010 </p>
                  <p>Thanchi, Bandarban </p>
                  <p>166,372</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                13. Support to farmers in the most vulnerable populations of the
                Chittagong Hill Tracts to ensure its food security Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Support to farmers in the most vulnerable populations of the
                    Chittagong Hill Tracts to ensure its food security
                  </p>
                  <p>AIDA, TARANGO</p>
                  <p>AIDA-AID </p>
                  <p>January 2010 to December 2012 </p>
                  <p>Bandarban Sadar </p>
                  <p>23,938,600</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                14. Improved Educational Services for Children of Indigenous
                Groups in CHT (IESC) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Improved Educational Services for Children of Indigenous
                    Groups in CHT (IESC)
                  </p>
                  <p>Save The Children</p>
                  <p>Save The Children Australia </p>
                  <p>2011 to 2013 </p>
                  <p>Bandarban Sadar, Thanchi & Alikadam </p>
                  <p>13,963,189</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>15. Our report towards our Bangladesh Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Our report towards our Bangladesh</p>
                  <p>Save The Children</p>
                  <p>Save The Children Australia </p>
                  <p>January 2012 to December 2013 </p>
                  <p>Bandarban Sadar </p>
                  <p>140,804</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                16. Promote and enabling environment for ethnic hilly women of
                Bangladesh to access and control over economic Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Promote and enabling environment for ethnic hilly women of
                    Bangladesh to access and control over economic
                  </p>
                  <p>UN Fund for Women</p>
                  <p>UN Women </p>
                  <p>January 2013 to December 2015 </p>
                  <p>Bandarban Sadar and Thanchi </p>
                  <p>16,714,304</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                17. Sanitation Marketing in Southern & Eastern Area Markets
                (SanMark-SEAMs) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Sanitation Marketing in Southern & Eastern Area Markets
                    (SanMark-SEAMs)
                  </p>
                  <p>iDE Bangladesh</p>
                  <p>UNICEF </p>
                  <p>January 2015 to August 2015 </p>
                  <p>Rangamati Sadar & Rajasthali </p>
                  <p>643,680</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                18. Wildlife protection medicinal plant conservation and
                reviving age-old herbal healing practice though ethnic community
                engagement Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Wildlife protection medicinal plant conservation and
                    reviving age-old herbal healing practice though ethnic
                    community engagement
                  </p>
                  <p>IUCN and KNCF</p>
                  <p>KNCF Japan </p>
                  <p>2008 to April 2015 </p>
                  <p>Thanchi, Bandarban </p>
                  <p>7,857,453</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                19. Enhancing Governance and Capacity of Services Providers and
                Civil Society in water Supply and Sanitation Sectors Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Enhancing Governance and Capacity of Services Providers and
                    Civil Society in water Supply and Sanitation Sectors
                  </p>
                  <p>NGO Forum for Public Health</p>
                  <p>European Commission </p>
                  <p>January 2012 to December 2016 </p>
                  <p>Ruma, Bandarban </p>
                  <p>1,903,722</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                20. Empowerment of Ethnic hilly women to access and control over
                economic resources Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Empowerment of Ethnic hilly women to access and control over
                    economic resources
                  </p>
                  <p>Manusher Jonno Foundation</p>
                  <p>UKAID </p>
                  <p>October 2013 to March 2017 </p>
                  <p>Bandarban Sadar </p>
                  <p>8,098,105</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>21. Bandaban Area Development Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Bandaban Area Development </p>
                  <p>World Vision Bangladesh</p>
                  <p>Netherlands </p>
                  <p>June 2014 to September 2017 </p>
                  <p>Bandarban Sadar </p>
                  <p>38,069,464</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                22. Achieving Resilience in Food Security and Nutrition in
                Remote Areas of the Chittagong Hill Tracts Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Achieving Resilience in Food Security and Nutrition in
                    Remote Areas of the Chittagong Hill Tracts
                  </p>
                  <p>FAO</p>
                  <p>European Commission </p>
                  <p>October 2016 to July 2017 </p>
                  <p>Alikadam </p>
                  <p>5,766,075</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>23. Water and Sanitation Program Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Water and Sanitation Program</p>
                  <p>Bangladesh NGO Foundation</p>
                  <p>Government Project </p>
                  <p>February 2017 to January 2018 </p>
                  <p>Bandarban Sadar </p>
                  <p>275,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                24. Emergency Livelihood Response for Cyclone Mora affected
                households in Lama Upazila Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Emergency Livelihood Response for Cyclone Mora affected
                    households in Lama Upazila
                  </p>
                  <p>FAO</p>
                  <p>European Commission </p>
                  <p>October 2017 to February 2018 </p>
                  <p>Lama, Bandarban </p>
                  <p>2,862,300</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>25. Safety Net Program Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Safety Net Program</p>
                  <p>Department of Women’s Affairs</p>
                  <p>Government Project </p>
                  <p>March 2017 to December 2018 </p>
                  <p>Rowangchari and Ruma, Bandarban </p>
                  <p>1,703,950</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                26. Organizing Ethnic Societies for Improved their Social Safety
                (OESISS) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Organizing Ethnic Societies for Improved their Social Safety
                    (OESISS)
                  </p>
                  <p>ActionAid Bangladesh</p>
                  <p>ActionAid Bangladesh </p>
                  <p>July 2006 to December 2018 </p>
                  <p>Thanchi, Bandarban </p>
                  <p>50,666,369</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                27. Strengthening Inclusive Development in The Chittagong Hill
                Tracts (SID-CHT) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Strengthening Inclusive Development in The Chittagong Hill
                    Tracts (SID-CHT)
                  </p>
                  <p>UNDP</p>
                  <p>USAID </p>
                  <p>November 2018 to July 2019 </p>
                  <p>Alikadam, Bandarban </p>
                  <p>10,662,870</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                28. Helping Children Growing as Active Citizen (HCGAC) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Helping Children Growing as Active Citizen (HCGAC)</p>
                  <p>Plan International Bangladesh</p>
                  <p>Save The Children </p>
                  <p>January 2017 to June 2020 </p>
                  <p>Bandarban Sadar </p>
                  <p>237,431</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                29. Improved Post harvest handling and professing technology for
                value addition of cashew and coffee in Chittagong Hill Tracts
                Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Improved Post harvest handling and professing technology for
                    value addition of cashew and coffee in Chittagong Hill
                    Tracts
                  </p>
                  <p>FAO</p>
                  <p>European Commission</p>
                  <p>June 2019 to November 2019</p>
                  <p>Ruma & Thanchi, Bandarban </p>
                  <p>902,370</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                30. Socio Economic Empowerment for Rural Communities (SEERC)
                Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Socio Economic Empowerment for Rural Communities (SEERC)
                  </p>
                  <p>HELVETAS Swiss Interco-operation Bangladesh</p>
                  <p>Embassy of Switzerland</p>
                  <p>September 2009 to March 2015</p>
                  <p>Bandarban Sadar, Rowangchari & Alikadam </p>
                  <p>15,547,646</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                31. Social Institutional Economic Empowerment of rural
                communities (SIEERC) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Social Institutional Economic Empowerment of rural
                    communities (SIEERC)
                  </p>
                  <p>HELVETAS Swiss Interco-operation Bangladesh</p>
                  <p>Embassy of Switzerland</p>
                  <p>July 2015 to December 2019</p>
                  <p>Bandarban Sadar & Alikadam </p>
                  <p>17,942,280</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                32. Sustainable Agriculture and Production Linked to Improved
                Nutrition Status, Resilience and Gender Equity (SAPLING) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Sustainable Agriculture and Production Linked to Improved
                    Nutrition Status, Resilience and Gender Equity (SAPLING)
                  </p>
                  <p>Helen Keller International</p>
                  <p>USAID</p>
                  <p>December 2017 to June 2020</p>
                  <p>Bandarban Sadar </p>
                  <p>982,160</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>33. Strengthen Traditional Herbal in CHT Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Strengthen Traditional Herbal in CHT</p>
                  <p>Land is Life</p>
                  <p>Thailand</p>
                  <p>July 2019 to June 2020</p>
                  <p>Thanchi, Bandarban </p>
                  <p>250,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>34. Bandarban Area Program Livelihood-TP Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Bandarban Area Program Livelihood-TP</p>
                  <p>World Vision Bangladesh</p>
                  <p>Netherlands</p>
                  <p>October 2017 to September 2020</p>
                  <p>Bandarban Sadar </p>
                  <p>12,460,234</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                35. Ensuring Reproductive Health Rights and Gender equality for
                Ethnic Hilly Women Bandarban Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Ensuring Reproductive Health Rights and Gender equality for
                    Ethnic Hilly Women Bandarban
                  </p>
                  <p>Diakonia Bangladesh</p>
                  <p>Embassy of Sweden</p>
                  <p>January 2011 to December 2020</p>
                  <p>Thanchi & Bandarban Sadar </p>
                  <p>34,197,090</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>36. Bandarban Area Program Livelihood-TP Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Bandarban Area Program Livelihood-TP</p>
                  <p>World Vision Bangladesh</p>
                  <p>Netherlands</p>
                  <p>October 2020 to September 2021</p>
                  <p>Bandarban Sadar </p>
                  <p>4,046,640</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                37. Swapno Siri (Early Childhood Care and Development) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Swapno Siri (Early Childhood Care and Development)</p>
                  <p>World Vision Bangladesh</p>
                  <p>Germany</p>
                  <p>October 2018 to September 2021</p>
                  <p>Bandarban Sadar </p>
                  <p>22,461,852</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                38. SOKKHOMOTA-I (Collaborate, Prepare and Resilient to Natural
                and Human induced disasters) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    SOKKHOMOTA-I (Collaborate, Prepare and Resilient to Natural
                    and Human induced disasters)
                  </p>
                  <p>Caritas Bangladesh & CSR</p>
                  <p>USAID</p>
                  <p>December 2019 to September 2021</p>
                  <p>Chokkong, Alikadam, Bandarban </p>
                  <p>17,622,632</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                39. Improved the hygienic and sanitary condition of Fish Market
                of Bandarban Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Improved the hygienic and sanitary condition of Fish Market
                    of Bandarban
                  </p>
                  <p>WorldFish</p>
                  <p>USAID</p>
                  <p>April 2021 to September 2021</p>
                  <p>Bandarban Sadar </p>
                  <p>6,05,660</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>40. Water and Sanitation Program Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Water and Sanitation Program</p>
                  <p>Bangladesh NGO Foundation</p>
                  <p>Government Project</p>
                  <p>November 2020 to November 2021</p>
                  <p>Bandarban Sadar </p>
                  <p>2,75,000</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                41. Contribution to gender equality, rights of girls and women
                from ethnic minorities and eradication of Gender-based Violence
                in Bandarban Hill District Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Contribution to gender equality, rights of girls and women
                    from ethnic minorities and eradication of Gender-based
                    Violence in Bandarban Hill District
                  </p>
                  <p>DanChurchAid (DCA)</p>
                  <p>DANIDA</p>
                  <p>February 2020 to December 2021</p>
                  <p>Bandarban Sadar </p>
                  <p>4,332,730</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>42. Activating Village Court Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Activating Village Court</p>
                  <p>UNDP & CHT Ministry</p>
                  <p>European Commission</p>
                  <p>December 2020 March 2022</p>
                  <p>Thanchi & Alikadam </p>
                  <p>6,782,692</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                43. Strengthen civil society and public institutions to address
                combating gender-based violence Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Strengthen civil society and public institutions to address
                    combating gender-based violence
                  </p>
                  <p>Manusher Jonno Foundation</p>
                  <p>Embassy of Sweden</p>
                  <p>July 2017 to June 2022</p>
                  <p>Bandarban Sadar </p>
                  <p>13,909,186</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                44. Attain Proficiency of Rural Indigenous Women and Girls
                through Justifiable, Intrinsic and Tenable Activities
                (APORAJITA) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Attain Proficiency of Rural Indigenous Women and Girls
                    through Justifiable, Intrinsic and Tenable Activities
                    (APORAJITA)
                  </p>
                  <p>Manusher Jonno Foundation</p>
                  <p>ESDO & UKAID</p>
                  <p>January 2019 to July 2022</p>
                  <p>Thanchi, Bandarban </p>
                  <p>10,123,511</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                45. SOKKHOMOTA-II (Collaborate, Prepare and Resilient to Natural
                and Human induced disasters) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    SOKKHOMOTA-II (Collaborate, Prepare and Resilient to Natural
                    and Human induced disasters)
                  </p>
                  <p>Caritas Bangladesh &CRS</p>
                  <p>USAID</p>
                  <p>January 2021 to October 2022</p>
                  <p>Lama, Bandarban </p>
                  <p>62,592,864</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>46. Nutrition Sensitive Aquaculture in Thanchi Project</h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>Nutrition Sensitive Aquaculture in Thanchi</p>
                  <p>WorldFish</p>
                  <p>USAID</p>
                  <p>March 2022 to August 2022</p>
                  <p>Thanchi, Bandarban </p>
                  <p>1,654,400</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                47. Promoting women and girls empowerment and self-reliance
                among Rohingya Camps and Host Communities Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Promoting women and girls empowerment and self-reliance
                    among Rohingya Camps and Host Communities
                  </p>
                  <p>DanChurchAid (DCA)</p>
                  <p>DANIDA</p>
                  <p>April 2022 to December 2022</p>
                  <p>Ukhia, Cox’s Bazar </p>
                  <p>3,888,479</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                48. Gender Responsive Education & Skills Programme in Chittagong
                Hill Tracts Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Gender Responsive Education & Skills Programme in Chittagong
                    Hill Tracts
                  </p>
                  <p>BRAC</p>
                  <p>Global Affairs Canada</p>
                  <p>November 2021 to December 2022</p>
                  <p>Ruma, Bandarban </p>
                  <p>3,136,697</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper third_project">
            <div className="container">
              <h3>
                49. South and Southeast Asian Community Base Trails Network
                (SEACTN) Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    South and Southeast Asian Community Base Trails Network
                    (SEACTN)
                  </p>
                  <p>BRAC</p>
                  <p>MORU Mahidol Oxford Tropical Medicine Research Unit</p>
                  <p>November 2021 to October 2023</p>
                  <p>Lama, Bandarban </p>
                  <p>1,221,236</p>
                </div>
              </div>
            </div>
          </div>

          <div className="single_project_wrapper second_project">
            <div className="container">
              <h3>
                50. Promoting women and girl’s empowerment and self-reliance
                among Rohingya Camps and Host Communities Project
              </h3>
              <div className="content_wrapper">
                <div className="particulars">
                  <p className="bold_font">Project Title</p>
                  <p>Project Name</p>
                  <p>Partnership Agency</p>
                  <p>Donors</p>
                  <p>Duration</p>
                  <p>Project Location</p>
                  <p>Budget</p>
                </div>
                <div className="details">
                  <p className="bold_font">Details</p>
                  <p>
                    Promoting women and girl’s empowerment and self-reliance
                    among Rohingya Camps and Host Communities
                  </p>
                  <p>DanChurchAid (DCA)</p>
                  <p>DANIDA</p>
                  <p>January 2023 to December 2023</p>
                  <p>Ukhia, Cox’s Bazar</p>
                  <p>4,609,575</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CompleteProject;
