import React from "react";
import Footer from "../shared/Footer/Footer";
import WhoWeAre from "./WhoweAre/WhoWeAre";
import Header from "./Header/Header";
import Navbar from "../shared/Navbar/Navbar";
import FundingPartner from "./FuncingPartner/FundingPartner";
import TranslateButton from "../TranslateButton/TranslateButton";
import Activity from "./Activity/Activity";
import GoalAndObjective from "./GoalAndObjective/GoalAndObjective";
import SocialMedia from "../SocialMedia/SocialMedia";

const Home = () => {
  return (
    <div>
      <Navbar />
      <TranslateButton />
      <Header />
      <WhoWeAre />
      <Activity />
      <GoalAndObjective />
      <FundingPartner />
      <SocialMedia />
      <Footer />
    </div>
  );
};

export default Home;
