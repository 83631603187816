import React from "react";
import { useEffect } from "react";
import "../../../style/resource/adminTeam.css";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import SaingMarma from "../../../images/Admin-team/Saing-saing-marma.png";
import ChainSaMarma from "../../../images/Admin-team/Chain-sa-mong-marma.jpg";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMedia from "../../SocialMedia/SocialMedia";

const AdminTeam = () => {
  useEffect(() => {
    document.title = `Bnks | Admin Team`;
  }, []);

  const shareUrl = "https://bnksbd.org/stay-inform/admin-team";

  return (
    <>
      <Navbar />
      <TranslateButton />
      <SocialMedia />

      <div className="admin_team_section">
        <div className="header_image"></div>
        {/* Social Share Section Start */}
        <div className="social_share_wrapper">
          <div className="container">
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Social Share Section End */}

        {/* Admin Team Main Part */}
        <div className="admin_team_wrapper">
          <div className="container">
            <h2>BNKS Admin Team Members</h2>
            <p>
              BNKS looks for qualified and potential staff and members to be
              part of its management structure and ensure full opportunity for
              all irrespective of gender, physical ability, religious beliefs,
              caste, and class. Nevertheless, in the case of appointment to
              certain posts, it will be specified whether they will be men or
              women for the intended purpose. BNKS will apply these policies
              during recruitment and staff management. It is important to ensure
              that the company’s recruitment policy is followed. Once the
              employees’ jobs are accepted by BNKS, they will be obliged to
              accept each other’s powers according to the organization’s
              position structure and will cooperate with colleagues in their
              work. They will also assist their supervisors according to their
              job description and position.
            </p>
            <div className="content_wrapper">
              <div className="single_wrapper">
                <div className="image">
                  <img src={SaingMarma} alt="" />
                </div>
                <h4>Ms. Saing Saing A Marma</h4>
                <h5>Manager Admin & HR</h5>
              </div>
              <div className="single_wrapper">
                <div className="image">
                  <img src={ChainSaMarma} alt="" />
                </div>
                <h4>Mr. Chain Sa Mong Marma</h4>
                <h5>Admin Officer</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdminTeam;
